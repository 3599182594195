import React from 'react';
import { Route } from 'react-router-dom';
import Login from './views/Login'
import PrivacyPolicy from './views/privacypolicy'
import RequestDeactivation from './views/RequestDeaction'

const LoginBaseRouter = () =>(
    <div>
        <Route exact path='/' component={Login}  />
        <Route exact path='/privacypolicy/' component={PrivacyPolicy} />
        <Route exact path='/request_accountdeaction/' component={RequestDeactivation} />

    </div>
);



export default LoginBaseRouter;


