import React from 'react';
import DeliveryAgentForm from '../components/DeliveryAgentForm'

import axios from 'axios'
import { Table, Input, Button,Collapse,Popover,Popconfirm,message,Descriptions,Modal,Spin, Card,Tooltip,Image,Avatar,Empty } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon from '@ant-design/icons';
import { SearchOutlined,DeleteOutlined,FundViewOutlined,DownloadOutlined,EditOutlined,WalletFilled,LoadingOutlined,EditFilled,AliyunOutlined,UserOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import CurrencyFormat from 'react-currency-format';
import * as serverconfig from '../../serverconn'
import {
  Col,
  Row,
} from 'reactstrap';
import ReactExport from "@ibrahimrahmani/react-export-excel";

var CryptoJS = require("crypto-js");

const { Panel } = Collapse;
const antIcon = <LoadingOutlined style={{ fontSize: 40 }} spin />;

function callback(key) {
  console.log(key);
}

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

var ngoid= ''
var bizuserid= ''
var token= ''

class Hub_AgentsList extends React.Component {
  state = {
    searchText: '',
    searchedColumn: '',
    delivery_agents: [],
    viewpaymentmodal:false,
    expensedetails:{},
    userprofile:{},
    datarequested:true,
    memberactivationformvisible:false,
    selectedmemberid:'',
    userrights:{},
    loaded_image:false
  };

  componentDidMount(){

    if(localStorage.getItem("ngoid")){
      ngoid= CryptoJS.AES.decrypt(localStorage.getItem("ngoid") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
    }else{
      ngoid= ''
    }

    
    if(localStorage.getItem("bizuserid")){
       bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
     }else{
        bizuserid= ''
     }
    
     if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

   this.componentmountactions();

}

//component mount method
componentmountactions=()=>{
  axios.get(serverconfig.backendserverurl+`/api/delivery_agents/?collection_hub=${localStorage.getItem("coodinated_hub")}`)
  .then(res => {
      this.setState({
        delivery_agents:res.data
      })
  })

  axios.get(serverconfig.backendserverurl+`/api/accountrights/?user=${bizuserid}`)
  .then(res => {
      this.setState({
        userrights:res.data[0]
      })
      this.setState({datarequested:false})
  })


}

    //////////////////////////////////////////////////
  //handle delete here
  handleDelete = id => {
    axios.delete(`${serverconfig.backendserverurl}/api/delivery_agents/${id}`).then(res =>{
      window.location.reload(false)
      message.info("successfully deleted") 
     }
     );

  };


getColumnSearchProps = dataIndex => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    <div style={{ padding: 8 }}>
      <Input
        ref={node => {
          this.searchInput = node;
        }}
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
      <Button
        type="primary"
        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        icon={<SearchOutlined />}
        size="small"
        style={{ width: 90, marginRight: 8 }}
      >
        Search
      </Button>
      <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
        Reset
      </Button>
    </div>
  ),
  filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilter: (value, record) =>
    record[dataIndex]
      .toString()
      .toLowerCase()
      .includes(value.toLowerCase()),
  onFilterDropdownVisibleChange: visible => {
    if (visible) {
      setTimeout(() => this.searchInput.select());
    }
  },
  render: text =>
    this.state.searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ) : (
      text
    ),
});

handleSearch = (selectedKeys, confirm, dataIndex) => {
  confirm();
  this.setState({
    searchText: selectedKeys[0],
    searchedColumn: dataIndex,
  });
};

handleReset = clearFilters => {
  clearFilters();
  this.setState({ searchText: '' });
};

  render() {

    const columns = [
      {
        title: 'Profile',
        dataIndex: 'profile_pic',
        key: 'profile_pic',
        render: (text,record) => <Avatar
        size={60}
        icon={<UserOutlined />}
        alt="User Pic"
        src={serverconfig.backendserverurl+`/customqueries/getfile/${Number(record.id)}/${'user_account'}`}
        />,

      },
      {
        title: 'Names',
        dataIndex: 'FullName',
        key: 'id',
        ...this.getColumnSearchProps('FullName'),
      },
      {
        title: 'Agent code',
        dataIndex: 'agent_code',
        key: 'id',
        ...this.getColumnSearchProps('agent_code'),
      },
      {
        title: 'Phone contact',
        dataIndex: 'RegisteredPhoneNumber1',
        key: 'id',
      },

      {
        title: 'ID',
        dataIndex: 'IDNumber',
        key: 'id',
      },

      {
        title: 'Actions',
        dataIndex: 'id',
        key: 'id',
        render: text =>
        <p>
        <Popover content={<p>Click here to edit,view details</p>} title="Edit,View">
         <Link to={`/deliveryagents/${text}`}><FundViewOutlined style={{color:'blue'}}/></Link>
        </Popover>
        </p>,
      },

    ];



    
    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
          <Spin size="large">
            <div className="content" />
          </Spin>           
        </div>
      )

    }else{
      return(
        <div>

            <ExcelFile filename={"UmojaIMCouriers -Agents"}  element={<Button type='primary' icon={<DownloadOutlined/>} >Download excel  data</Button>}>
                <ExcelSheet data={this.state.delivery_agents} name="delivery_agents bio">
                    <ExcelColumn label="Name" value="FullName"/>
                    <ExcelColumn label="Tel" value="RegisteredPhoneNumber1"/>
                    <ExcelColumn label="ID number" value="IDNumber"/>
                    <ExcelColumn label="Agent code" value="agent_code"/>
                </ExcelSheet>
            </ExcelFile>
            <br></br>
            <br></br>

            <Table 
             scroll={{ x: 1000 }}
             columns={columns}
             pagination={{showQuickJumper:true,showSizeChanger:true }}
             dataSource={this.state.delivery_agents} 
             bordered/>


        </div>
    )

    }

  }
}

export default Hub_AgentsList; 
