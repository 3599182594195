import React from 'react';
import { Form, Input, Button,Spin,Select } from 'antd';
import axios from 'axios'
import * as serverconfig from '../../serverconn'
import { UserOutlined, LockOutlined, LoadingOutlined, MailOutlined,UploadOutlined } from '@ant-design/icons';
import PhoneInput from 'react-phone-number-input'

var CryptoJS = require("crypto-js");
const { Option } = Select;

const FormItem=Form.Item;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;



var token= ''
var sacco= ''


class HealthCenterForm extends React.Component {

  state = {
    name:'' ,  
    idtypeDescription:'',
    IDvaliditydays:0,
    datarequested:false,

    contactperson_name:'',
    District:'',
    ugdistricts:[],
    contactperson_phone:'',
    location_longitude:0,
    location_latitude:0,

    collectionhubs: [],
    selected_hub:''


  }

  componentDidMount(){
    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
    }else{
       sacco= ''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }


    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    //get the instance_id package here
    axios.get(serverconfig.backendserverurl+`/api/ugdistricts/`)
    .then(res => {
      this.setState({
        ugdistricts:res.data
      })

    })

    axios.get(serverconfig.backendserverurl+`/api/collectionhubs`)
    .then(res => {
        this.setState({
            collectionhubs:res.data
        })
        this.setState({datarequested:false})
    })

  }


  //submit button pressed
  handleFormSubmit=(event) =>{
    this.setState({datarequested:true})

    let form_data = new FormData();
    form_data.append('name', this.state.name);
    form_data.append('contactperson_name', this.state.contactperson_name);
    form_data.append('contactperson_phone', this.state.contactperson_phone);

    form_data.append('district', this.state.District);

    form_data.append('location_latitude', this.state.location_latitude);
    form_data.append('location_longitude', this.state.location_longitude);
    form_data.append('collection_hub', this.state.selected_hub);

    axios.post(serverconfig.backendserverurl+'/api/healthcentres/', form_data, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    })
  .then(res =>    
    this.setState({datarequested:false})
,    window.location.reload(false)
  )
  .catch(error => console.log(error))

  }

  handlenameChange= (e) => this.setState({ name: e.target.value});
  handleIDDescriptionChange= (e) => this.setState({ idtypeDescription: e.target.value});

  render(){

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{
      return (
        <div>
    <Form
        onFinish={(event) => this.handleFormSubmit(event)}
    >
      <FormItem label="Health center Name"
        name="idname"
        rules={[
          {
            required: true,
            message: 'Please input name',
          },
        ]}
      >
        <Input name="name"  placeholder="Put a name here." value={this.state.name} onChange={this.handlenameChange} />
      </FormItem>


      <FormItem label="Contact person name"
        name="contactperson_name"
        rules={[
          {
            required: true,
            message: 'Please input contactperson_name',
          },
        ]}    
      >
        <Input name="contactperson_name"  placeholder="Name here" value={this.state.contactperson_name} onChange={(val)=>{this.setState({contactperson_name:val.target.value})}} />
      </FormItem>

      <FormItem label= { <h6 style={{display:'flex'}} >Contact person phone contact</h6>}                       
      >
         <PhoneInput
            countrySelectProps={{ unicodeFlags: true }}
            defaultCountry="UG"
            placeholder="Enter phone number"
            value={this.state.contactperson_phone} onChange={(val)=>{this.setState({contactperson_phone:val})}}/>
       </FormItem> 

     
      <FormItem label="District"
          name="district"
          rules={[
            {
              required: true,
              message: 'Please enter district of residence',
            },
          ]}           
      >
        <Select
          placeholder="Ug District"
          style={{  }} 
          value={this.state.District}
            onChange={(val)=>{this.setState({District:val})}} 
            
            showSearch
            optionFilterProp="children"
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            onSearch={this.onSearch}
            >
          {this.state.ugdistricts.map(
            (dist)=>(
              <Option value={dist.district_name}>{dist.district_name} | {dist.region}</Option>
            ))}
        </Select>
      </FormItem>



      <FormItem label="Address latitude"    
      >
        <Input  type='number'  name="Address latitude"  placeholder="Latitude here" value={this.state.location_latitude} onChange={(val)=>{this.setState({location_latitude:val.target.value})}} />
      </FormItem>

      <FormItem label="Address longitude"    
      >
        <Input  type='number'  name="Address longitude"  placeholder="Longitude here" value={this.state.location_longitude} onChange={(val)=>{this.setState({location_longitude:val.target.value})}} />
      </FormItem>

      <FormItem label="Attach collection hub"
        name="hub"
        rules={[
          {
            required: true,
            message: 'Please select hub',
          },
        ]}  
      
      >
          <Select 
          placeholder="Hub"
          style={{ width: 200 }} 
          value={this.state.selected_hub} 
          onChange={(val)=>{this.setState({selected_hub:val})}}

          showSearch
          optionFilterProp="children"
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          onSearch={this.onSearch}
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }                     
          >
            {this.state.collectionhubs.map(
              (item)=>(
                <Option value={item.id}>{item.name}</Option>
              ))}
          </Select>
       </FormItem>


      <FormItem>
        <Button  type="primary" htmlType="submit">{this.props.btnText}</Button>
      </FormItem>

      </Form>
        </div>
      );

    }
    
  }

}


export default HealthCenterForm;
