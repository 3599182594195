import React from 'react';
import DeliveryAgentForm from '../components/DeliveryAgentForm'

import axios from 'axios'
import { Table, Input, Button,Collapse,Popover,Popconfirm,Switch,message,Form,Descriptions,Modal,Spin, Card,Tooltip,Image,Avatar,Empty } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon from '@ant-design/icons';
import { SearchOutlined,DeleteOutlined,FundViewOutlined,LockOutlined,DownloadOutlined,LockFilled,EditOutlined,WalletFilled,LoadingOutlined,EditFilled,AliyunOutlined,UserOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import CurrencyFormat from 'react-currency-format';
import * as serverconfig from '../../serverconn'
import {
  Col,
  Row,
} from 'reactstrap';
import ReactExport from "@ibrahimrahmani/react-export-excel";

var CryptoJS = require("crypto-js");

const { Panel } = Collapse;
const antIcon = <LoadingOutlined style={{ fontSize: 40 }} spin />;

function callback(key) {
  console.log(key);
}

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

var ngoid= ''
var bizuserid= ''
var token= ''

class AgentList extends React.Component {
  state = {
    searchText: '',
    searchedColumn: '',
    delivery_agents: [],
    viewpaymentmodal:false,
    expensedetails:{},
    userprofile:{},
    datarequested:true,
    memberactivationformvisible:false,
    selectedmemberid:'',
    userrights:{},
    loaded_image:false,
    selectedid:'',
    showpasswordupdatemodal:false
  };

  componentDidMount(){

    if(localStorage.getItem("ngoid")){
      ngoid= CryptoJS.AES.decrypt(localStorage.getItem("ngoid") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
    }else{
      ngoid= ''
    }

    
    if(localStorage.getItem("bizuserid")){
       bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
     }else{
        bizuserid= ''
     }
    
     if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

   this.componentmountactions();

}

//component mount method
componentmountactions=()=>{
  axios.get(serverconfig.backendserverurl+`/api/delivery_agents/`)
  .then(res => {
      this.setState({
        delivery_agents:res.data
      })

      console.log(res.data)
  })

  axios.get(serverconfig.backendserverurl+`/api/accountrights/?user=${bizuserid}`)
  .then(res => {
      this.setState({
        userrights:res.data[0]
      })
      this.setState({datarequested:false})
  })


}



getColumnSearchProps = dataIndex => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    <div style={{ padding: 8 }}>
      <Input
        ref={node => {
          this.searchInput = node;
        }}
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
      <Button
        type="primary"
        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        icon={<SearchOutlined />}
        size="small"
        style={{ width: 90, marginRight: 8 }}
      >
        Search
      </Button>
      <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
        Reset
      </Button>
    </div>
  ),
  filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilter: (value, record) =>
    record[dataIndex]
      .toString()
      .toLowerCase()
      .includes(value.toLowerCase()),
  onFilterDropdownVisibleChange: visible => {
    if (visible) {
      setTimeout(() => this.searchInput.select());
    }
  },
  render: text =>
    this.state.searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ) : (
      text
    ),
});

handleSearch = (selectedKeys, confirm, dataIndex) => {
  confirm();
  this.setState({
    searchText: selectedKeys[0],
    searchedColumn: dataIndex,
  });
};

handleReset = clearFilters => {
  clearFilters();
  this.setState({ searchText: '' });
};

  render() {

    const columns = [
      {
        title: 'Profile',
        dataIndex: 'profile_pic',
        key: 'profile_pic',
        render: (text,record) => <Avatar
        size={60}
        icon={<UserOutlined />}
        alt="User Pic"
        src={record.Photo}
        />,

      },
      {
        title: 'Names',
        dataIndex: 'FullName',
        key: 'id',
        ...this.getColumnSearchProps('FullName'),
      },
      {
        title: 'Agent code',
        dataIndex: 'agent_code',
        key: 'id',
        ...this.getColumnSearchProps('agent_code'),
      },
      {
        title: 'Phone contact',
        dataIndex: 'RegisteredPhoneNumber1',
        key: 'id',
      },

      {
        title: 'ID',
        dataIndex: 'IDNumber',
        key: 'id',
      },

      {
        title: 'Last login',
        dataIndex: 'login_at',
        key: 'id',
      },
      {
        title: 'Last logout',
        dataIndex: 'logout_at',
        key: 'id',
      },

      {
        title: 'Active?',
        dataIndex: 'id',
        key: 'id',
          render: (text,record) =>
            <span>
            <Switch
            checked={record.active}
            onChange={val=>{
  
              let form_data = new FormData();
              form_data.append('is_online',record.is_online);
              form_data.append('active',val);
              axios.put(serverconfig.backendserverurl+`/api/delivery_agents/${record.id}/`,
                form_data, {
                headers: {
                  'content-type': 'multipart/form-data'
                }
              })
            .then(res =>  {
              this.setState({datarequested:false})
              this.componentmountactions()
              message.info("Updated")
          
            }).catch(error => console.log(error))
          
            }}
            checkedChildren="Yes"
            unCheckedChildren="No"
            />
            </span>
        },
      {
        title: 'Online?',
        dataIndex: 'id',
        key: 'id',
        render: (text,record) =>
          <p>{record.is_online?"Yes":"No"}</p>
      },

      {
        title: 'Actions',
        dataIndex: 'id',
        key: 'id',
        render: text =>
        <p>
        <Popover content={<p>Click here to edit,view details</p>} title="Edit,View">
         <Link to={`/deliveryagents/${text}`}><FundViewOutlined style={{color:'blue'}}/></Link>
        </Popover>

        &nbsp;&nbsp;&nbsp;
        <Popover content={<p>Click to change password</p>} title="Password Change">
          <LockFilled style={{color:'#154360'}}
           onClick={() => {
            this.setState({showpasswordupdatemodal:true})
            this.setState({selectedid:text})
            
            }}/>
        </Popover>

        </p>,
      },

    ];


    
    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
          <Spin size="large">
            <div className="content" />
          </Spin>           
        </div>
      )

    }else{
      return(
        <div>

            <ExcelFile filename={"UmojaIMCouriers -Agents"}  element={<Button type='primary' icon={<DownloadOutlined/>} >Download excel  data</Button>}>
                <ExcelSheet data={this.state.delivery_agents} name="delivery_agents bio">
                    <ExcelColumn label="Name" value="FullName"/>
                    <ExcelColumn label="Tel" value="RegisteredPhoneNumber1"/>
                    <ExcelColumn label="ID number" value="IDNumber"/>
                    <ExcelColumn label="Agent code" value="agent_code"/>
                </ExcelSheet>
            </ExcelFile>
            <br></br>
            <br></br>

            <Table 
             scroll={{ x: 1000 }}
             columns={columns}
             pagination={{showQuickJumper:true,showSizeChanger:true }}
             dataSource={this.state.delivery_agents} bordered/>


            <br></br>
            <br></br>


            <Collapse defaultActiveKey={['0']} onChange={callback}>
            <Panel header="Collapse Panel to open agent entry Form" key="1">
              <h2>Create new agent here</h2>
              <DeliveryAgentForm /> 
            </Panel>
            </Collapse>  


            <Modal              
                visible={this.state.showpasswordupdatemodal}
                title="Password change Form"
                width={600}
                onCancel={()=>{
                  this.setState({showpasswordupdatemodal:false})
                }}

                footer={[
                  <Button key="back" onClick={()=>{
                    this.setState({showpasswordupdatemodal:false})
                  }}>
                    Cancel
                  </Button>
                  ]}
                  >



              <Form
                onFinish={(values)=>{
                this.setState({datarequested:true})

                let form_data = new FormData();
                form_data.append('password', values.new_password1);
                form_data.append('agent_id', this.state.selectedid);
              
                //Now send the post resquest
                  axios.post(serverconfig.backendserverurl+'/customqueries/updateagentpassword', form_data, {
                  headers: {
                    'content-type': 'multipart/form-data'
                  }
                  })
                .then(res => {
                  this.setState({datarequested:false})
                  message.info(res.data.message);
                  this.setState({showpasswordupdatemodal:false})
                }
                )
                .catch(error => console.log(error)) 
            

              }}        
              name="passwordreset"
              className="login-form">
        
              <Form.Item
                  name="new_password1"
                  label="New Password "
                  rules={[
                    {
                      required: true,
                      message: 'Please input your password!',
                    },
                  ]}
                  hasFeedback
                >
                  <Input.Password 
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  type="password"
                  placeholder="Password one"
                  />


                </Form.Item>

          
              
              <Form.Item >
                  <Button type="primary" htmlType="submit" className="login-form-button">
                    Confirm Change
                  </Button>
              </Form.Item>
            </Form>
                    
            </Modal>



        </div>
    )

    }

  }
}

export default AgentList; 
