import React from 'react';
import { Form, Input, Button,Spin,Select,Result,DatePicker,Image,Switch,Card, message } from 'antd';
import axios from 'axios'
import * as serverconfig from '../../serverconn'
import { UserOutlined, LockOutlined, LoadingOutlined, MailOutlined,UploadOutlined } from '@ant-design/icons';
import moment from 'moment';
import {
  Col,
  Row,
} from 'reactstrap';

var CryptoJS = require("crypto-js");
const dateFormat = 'DD/MM/YYYY';

const FormItem=Form.Item;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

var token= ''
var username=''
var instance_id = "";

const { Option } = Select;

const { TextArea } = Input;
const t_time="HH:mm:ss"

class OrderUpdateForm extends React.Component {

  state = {
    client_name:'' ,  
    client_phone:'',
    datarequested:true,
    active:false,
    image:null,
    image_file:'',
    date:'',
    date_of_expiry:'',
    order_total:0,
    area:'',
    restaurant_profile:{},
    hub_zones:[],
    delivery_price:0,
    hub_riders:[],
    selected_rider:'',
    exclusive_riders:[],
    support_riders:[],
    datasubmittedsuccessfully:false,
    areas:[],
    reason_ofupdate:'',
    companyprofile:{},
    restaurants: [],
    restaurant:'',
    order_status:''


  }

  componentDidMount(){
    this.component_load()
  }


  //component load
  component_load=()=>{
    this.data_loaded()
  
  }

  data_loaded=()=>{
    this.setState({datarequested:true})

    axios
      .get(`${serverconfig.backendserverurl}/api/app_profile/${1}`)
      .then((res) => {
        this.setState({
          companyprofile: res.data,
        });
      });


  //get oder object
  let order_obj=this.props.order_obj

  this.setState({date:order_obj.orig_date})

  if(localStorage.getItem("token")){
     token= localStorage.getItem("token")
  }else{
     token= ''
  }

  if(localStorage.getItem("username")){
    username= CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)
  }else{
    username=''
  }

  axios.defaults.client_nameers={
    "Content-Type":"application/json",
    Authorization:`Token ${token}`
  }


  this.setState({datarequested:false})
  this.setState({datasubmittedsuccessfully:false})

  }


  //submit button pressed
  handleFormSubmit=(event) =>{
    var order_id=this.props.order_obj.id

    this.setState({datarequested:true})
    let form_data = new FormData();
    form_data.append('date',this.state.date);
    form_data.append('order_id',order_id);

    axios.post(serverconfig.backendserverurl+'/customqueries/update_order', form_data, {
      client_nameers: {
        'content-type': 'multipart/form-data'
      }
    })
  .then(res => {
     this.setState({datarequested:false})
     //this.setState({datasubmittedsuccessfully:true})
     this.data_loaded()

     //update parent
     this.props.onRefresh()
     
    })
    .catch(error => console.log(error))


  }

  //get the delivery zone

  
  render(){
    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )
    }else{

      if(this.state.datasubmittedsuccessfully===true){
        return(
          <div>
              <Card>
              <Result
              status="success"
              title="Action successful"
              extra={[
                <Button  type='primary' 
                onClick={()=>{
                  this.component_load()
                }}>Finish</Button>,
              ]}
              />

              </Card>
          </div>
        )    

      }else{

      return (
        <div>
          <h3>ORDER UPDATE FORM</h3>
          <Row>

            <Col xs="12" sm="6" lg="6" style={{padding: '5px'}}>
            <Card>
            <Form
                onFinish={(event) => this.handleFormSubmit(event)}
            >

            <FormItem label={"Date ("+this.state.date+")"}>
               <DatePicker onChange={(date, dateString)=>{this.setState({ date: dateString})}} format={'DD/MM/YYYY'} />
            </FormItem>

            <FormItem>
              <Button  type="primary" htmlType="submit">Update order</Button>
            </FormItem>
            </Form>

        </Card>
        </Col>
        </Row>

        </div>
      );
    }
    }

  }

}


export default OrderUpdateForm;



