import React from 'react';
import HubForm from '../components/HubForm'
import axios from 'axios'
import { Table, Input, Button,Collapse,Popover,Popconfirm,message,Spin, Tabs } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon from '@ant-design/icons';
import { SearchOutlined,DeleteOutlined,FundViewOutlined,LoadingOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import * as serverconfig from '../../serverconn'
import * as serversocketconfig from '../../serversocketconfig'
import ReactToPrint from 'react-to-print';
import * as reactstrp from 'reactstrap';
import * as primarycolor from '../../primarycolor'

import ReconnectingWebSocket from 'reconnecting-websocket';
import {
  withGoogleMap,
  withScriptjs,
  GoogleMap,
  DirectionsRenderer,
  Marker
} from "react-google-maps";

import SportsMotorsportsIcon from '@mui/icons-material/SportsMotorsports';

import PolylineMap from '../components/PolylineMap';


var CryptoJS = require("crypto-js");

const { Panel } = Collapse;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const { TabPane } = Tabs;

function callback(key) {
  console.log(key);
}

var agentlocationsocket = new ReconnectingWebSocket(serversocketconfig.socketurl+'/ws/agentlocationsocket/');

var token= ''
var sacco= ''


class OnlineAgentsList extends React.Component {
  state = {
    searchText: '',
    searchedColumn: '',
    pending_deliveries: [],
    datarequested:true,
    delivery_agents:[],
    concluded_deliveries: [],
  };


  componentDidMount(){
    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
    }else{
       sacco= ''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }


    axios.get(serverconfig.backendserverurl+`/api/delivery_agents/?is_online=${true}&active=${true}`)
    .then(res => {
        this.setState({
          delivery_agents:res.data
        })
        console.log(res.data)

        this.setState({datarequested:false})
    })

 

    //rerold on agnet location change
    agentlocationsocket.onmessage = async (e) => {
      //just update agent location
      this.setState({
        delivery_agents:[]
      })

      axios.get(serverconfig.backendserverurl+`/api/delivery_agents/?is_online=${true}&active=${true}`)
      .then(res => {
          this.setState({
            delivery_agents:res.data
          })  

          console.log(res.data)

      }) 


    };

}

getColumnSearchProps = dataIndex => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    <div style={{ padding: 8 }}>
      <Input
        ref={node => {
          this.searchInput = node;
        }}
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
      <Button
        type="primary"
        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        icon={<SearchOutlined />}
        size="small"
        style={{ width: 90, marginRight: 8 }}
      >
        Search
      </Button>
      <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
        Reset
      </Button>
    </div>
  ),
  filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilter: (value, record) =>
    record[dataIndex]
      .toString()
      .toLowerCase()
      .includes(value.toLowerCase()),
  onFilterDropdownVisibleChange: visible => {
    if (visible) {
      setTimeout(() => this.searchInput.select());
    }
  },
  render: text =>
    this.state.searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ) : (
      text
    ),
});

handleSearch = (selectedKeys, confirm, dataIndex) => {
  confirm();
  this.setState({
    searchText: selectedKeys[0],
    searchedColumn: dataIndex,
  });
};

handleReset = clearFilters => {
  clearFilters();
  this.setState({ searchText: '' });
};


  render() {


    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{
      return(
        <div>
          {
            this.state.delivery_agents.length>0?
            <PolylineMap initialCoordinates={this.state.delivery_agents} />
            :
            null
          }
 
        </div>
    )

    }

   
  }
}

export default OnlineAgentsList; 
