import React from 'react';
import SampleForm from '../components/SampleForm'
import axios from 'axios'
import { Table, Input, Button,Collapse,Tabs,Popover,Modal,Popconfirm,message,Spin } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon from '@ant-design/icons';
import { SearchOutlined,DeleteOutlined,FundViewOutlined,LoadingOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import * as serverconfig from '../../serverconn'
import * as reactstrp from 'reactstrap';
import SampleBarCodeForm from '../components/SampleBarCodeForm'
import BarCodeView from './BarCodeView'

var CryptoJS = require("crypto-js");

const { Panel } = Collapse;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

function callback(key) {
  console.log(key);
}
const { TabPane } = Tabs;


var token= ''
var sacco= ''

class SampleList extends React.Component {
  state = {
    searchText: '',
    searchedColumn: '',
    samples: [],
    datarequested:true,
    selected_sample:'',
    sample_barcodes:[],
    show_qrModel:false,
    selected_record:{}

  };

  componentDidMount(){
    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
    }else{
       sacco= ''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(serverconfig.backendserverurl+`/api/samples`)
    .then(res => {
        this.setState({
            samples:res.data
        })
        this.setState({datarequested:false})

    })
}

getColumnSearchProps = dataIndex => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    <div style={{ padding: 8 }}>
      <Input
        ref={node => {
          this.searchInput = node;
        }}
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
      <Button
        type="primary"
        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        icon={<SearchOutlined />}
        size="small"
        style={{ width: 90, marginRight: 8 }}
      >
        Search
      </Button>
      <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
        Reset
      </Button>
    </div>
  ),
  filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilter: (value, record) =>
    record[dataIndex]
      .toString()
      .toLowerCase()
      .includes(value.toLowerCase()),
  onFilterDropdownVisibleChange: visible => {
    if (visible) {
      setTimeout(() => this.searchInput.select());
    }
  },
  render: text =>
    this.state.searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ) : (
      text
    ),
});

handleSearch = (selectedKeys, confirm, dataIndex) => {
  confirm();
  this.setState({
    searchText: selectedKeys[0],
    searchedColumn: dataIndex,
  });
};

handleReset = clearFilters => {
  clearFilters();
  this.setState({ searchText: '' });
};

    //////////////////////////////////////////////////
  //handle delete here
  handleDelete = id => {
    axios.delete(`${serverconfig.backendserverurl}/api/samples/${id}`).then(res =>{
      window.location.reload(false)
      message.info("successfully deleted") 
     }
     );
  };

  render() {
    const columns = [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'id',
        ...this.getColumnSearchProps('name'),
      },
      {
        title: 'Description',
        dataIndex: 'description',
        key: 'id',
      },
      {
        title: 'Action',
        dataIndex: 'id',
        key: 'id',
        render: text =>
        <p>
        <Popover content={<p>Click here to edit,view details</p>} title="Edit,View">
         <Link to={`/samples/${text}`}><FundViewOutlined style={{color:'blue'}}/></Link>
        </Popover>
        &nbsp;&nbsp;&nbsp;
        <Popover content={<p>Click here to delete client</p>} title="Delete">
        <Popconfirm title="Sure to delete?" onConfirm={() => this.handleDelete(text)}>
          <DeleteOutlined style={{color:'red'}}/>
         </Popconfirm>
        </Popover>
        </p>,
      }

    ];

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{
      return(
        <div>
            <Collapse defaultActiveKey={['1']} onChange={this.callback}>
            <Panel header="ALL samples" key="1">
            <Table 
            columns={columns} 
            scroll={{ x: 1000 }}
            pagination={{showQuickJumper:true,showSizeChanger:true }}
            dataSource={this.state.samples}
            bordered
            
            /*
            expandable={{
              expandedRowRender: (record) =>{
                  return (
                    <div
                      style={{
                        margin: 0,
                      }}
                      >
                  <Tabs>

                  <TabPane tab="Sample barcodes" key="01" >

                        <reactstrp.Table bordered>
                        <thead>
                          <tr>
                            <th>Barcode type</th>
                            <th>Code</th>
                            <th>View</th>
                          </tr>
                        </thead>
                        <tbody>
                        {this.state.sample_barcodes.map(
                          (itm)=>(
                            <tr>
                              <td>{itm.bar_code_type}</td>
                              <td>{itm.bar_code}</td>
                              <td><Button onClick={()=>{

                                this.setState({show_qrModel:true})
                                this.setState({selected_record:itm})
                              }}>View code</Button></td>
                            </tr>
                          ))}
                          
                      </tbody>
                      </reactstrp.Table>
                  </TabPane>

                  <TabPane tab="Create new code" key="02" >
                    <SampleBarCodeForm sample_id={record.id}  />

                   </TabPane>

                  </Tabs>
    
                   </div>
                   );
              } ,
              rowExpandable: (record) => true,
              onExpand:(condition,record)=>{
                axios.get(serverconfig.backendserverurl+`/api/sample_barcodes/?sample=${record.id}`)
                .then(res => {
                    this.setState({
                      sample_barcodes:res.data
                    })
            
                })

              },
            }} */
            
            
            
            />
             </Panel>
             </Collapse>
            <br />
            <Collapse defaultActiveKey={['0']} onChange={callback}>
            <Panel header="Collapse Panel to open Form" key="1">
              <h2>Create new sample here</h2>
              <SampleForm 
              requestType="post"
              idtypeID={null}
              btnText="Create"
              /> 
            </Panel>
            </Collapse>


            {
              this.state.show_qrModel?
              <Modal              
              visible={this.state.show_qrModel}
              title="Barcode display"
              width={1000}
              onCancel={()=>{this.setState({show_qrModel:false})}}

              footer={[
                <Button key="back" onClick={()=>{this.setState({show_qrModel:false})}}>
                  Cancel
                </Button>
                ]}
                >
                  <BarCodeView 
                    selected_record={this.state.selected_record}
                    title={"SAMPLE BARCODE "}
                    />
           
              </Modal>

              :
              null
            }

        </div>
    )


    }

   
  }
}

export default SampleList; 
