import React from 'react';
import { GoogleMap, Polyline, LoadScript,Marker,InfoWindow } from '@react-google-maps/api';
import * as primarycolor from '../../primarycolor'
import SportsMotorsportsIcon from '@mui/icons-material/SportsMotorsports';
import * as map_apikey from '../../map_api_key'

const PolylineMap_Activities = ({ initialCoordinates }) => {
  const [coordinates, setCoordinates] = React.useState(initialCoordinates);

  const mapContainerStyle = {
    width: '100%',
    height: '400px',
  };

  const center = {
    lat: 0.3476, // Kampala latitude
    lng: 32.5825, // Kampala longitude
  };

  const defaultZoom = 12; // Adjust the zoom level as needed

  const [selectedMarker, setSelectedMarker] = React.useState(null);
  const handleMarkerClick = (marker) => {
    setSelectedMarker(marker);
  };

  
 
  return (
    <LoadScript googleMapsApiKey={'AIzaSyD7DhyWsX8qUNdEri4hGRjDOx566po_UpA'}>

      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        center={center}
        zoom={defaultZoom} >

        {coordinates.map((marker, index) => (
          <Marker
            key={index}
            position={{ lat: Number(marker.lat), lng: Number(marker.lng) }}
            onClick={() => handleMarkerClick(marker)}
          />
        ))}
        {selectedMarker && (
          <InfoWindow
            position={{ lat: Number(selectedMarker.lat), lng: Number(selectedMarker.lng) }}
            onCloseClick={() => setSelectedMarker(null)}
          >
            <div>
              <h3>{selectedMarker.activity_name}</h3>
            </div>
          </InfoWindow>
        )}

        {coordinates.map((coords, index) => (
          <Polyline
            key={index}
            path={coordinates.map(coord => ({ lat: Number(coord.lat), lng: Number(coord.lng) }))}
            options={{
              strokeColor: primarycolor.primarycolor,
              strokeOpacity: 4,
              strokeWeight: 3,
            }}
          />
        ))}

      </GoogleMap>
      </LoadScript>
  );
};

export default PolylineMap_Activities;