import React from 'react';
import axios from 'axios';
import { Form, Input, Button,Select,message,Popconfirm,Popover,Divider } from 'antd';
import {
    Col,
    Row,
  } from 'reactstrap';
import { UserOutlined } from '@ant-design/icons';
import * as serverconfig from '../../serverconn'
import PhoneInput from 'react-phone-number-input'

import { 
    DeleteOutlined,
    FundViewOutlined,
    LoadingOutlined
  } from '@ant-design/icons';


const FormItem=Form.Item;
const { Option } = Select;
class SupplierDetail extends React.Component{

    state={
        supplier: {},
        supplier_name:'' ,  
        phone_one:'',
        address:'',
        email:'',
        product_list:[],
        products: [],
        selected_product:'',
    

    }

    componentDidMount(){

        axios.get(serverconfig.backendserverurl+`/api/products/`)
        .then(res => {
            this.setState({
                products:res.data
            })
        })


        const supplierID=this.props.match.params.supplierID;
        axios.get(`${serverconfig.backendserverurl}/api/suppliers/${supplierID}`)
        .then(res => {  
            this.setState({supplier: res.data})

            this.setState({supplier_name: res.data.supplier_name})
            this.setState({phone_one: res.data.phone_one})
            this.setState({email: res.data.email})
            this.setState({address: res.data.address})
            this.setState({product_list:res.data.product_list===null?[]:JSON.parse(res.data.product_list)})

        })

    }


   //product exists
  isProductAlreadyExists = (product_name, emailList) => {
    return emailList.some((item) => item.product_name === product_name);
  };



//submit button pressed
handleFormSubmit=(event) =>{
    const supplierID=this.props.match.params.supplierID;

    let form_data = new FormData();
    form_data.append('supplier_name', this.state.supplier_name);
    form_data.append('phone_one', this.state.phone_one);
    form_data.append('email', this.state.email);
    form_data.append('address', this.state.address);
    form_data.append('product_list',JSON.stringify(this.state.product_list));

      axios.put(serverconfig.backendserverurl+`/api/suppliers/${supplierID}/`, form_data, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      })
    .then(res => window.location.reload(false)
    )
    .catch(error => console.log(error))

  }

  handlesupplier_nameChange= (e) => this.setState({ supplier_name: e.target.value});
  handledistrictChange= (e) => this.setState({ district: e.target.value});
  handlesubcountyChange= (e) => this.setState({ subcounty: e.target.value});
  handlevillageChange= (e) => this.setState({ village: e.target.value});
  handlephone_oneChange= (e) => this.setState({ phone_one: e});
  handlephone_twoChange= (e) => this.setState({ phone_two: e});
  handleemailChange= (e) => this.setState({ email: e.target.value});
  handlestreetChange= (e) => this.setState({ street: e.target.value});
  handlebuildingChange= (e) => this.setState({ building: e.target.value});
  handlefloorChange= (e) => this.setState({ floor: e.target.value});
  handleoffice_numberChange= (e) => this.setState({ office_number: e.target.value});


    handleDelete=(event)=>{
        const supplierID=this.props.match.params.supplierID;
        axios.delete(`${serverconfig.backendserverurl}/api/suppliers/${supplierID}`);
    }

    render(){
        return(
            <div>
              
          <h4>Supplier Update</h4>
          <Form
              onFinish={(event) => this.handleFormSubmit(event)}
          >
            <FormItem label="Supplier Name"
       
          >
            <Input  placeholder="Put a name here." value={this.state.supplier_name} onChange={this.handlesupplier_nameChange} />
          </FormItem>


          <FormItem label="Phone One" 
                    
          >
          <PhoneInput
                countrySelectProps={{ unicodeFlags: true }}
                defaultCountry="UG"
                placeholder="Enter phone number"
                value={this.state.phone_one} onChange={this.handlephone_oneChange}/>
           </FormItem> 


          <FormItem label="Email"
         
          >
            <Input placeholder="email." value={this.state.email} onChange={this.handleemailChange} />
          </FormItem>

          <FormItem label="Address "
        
          >
            <Input  placeholder="Put a address here." value={this.state.address} onChange={(val)=>{this.setState({address:val.target.value})}} />
          </FormItem>


          <FormItem label="Select product to add to list">
                <Select placeholder="Product" 
                value={this.state.selected_product} 
                onChange={(val)=>{
                  //selected product
                  this.setState({selected_product:val})

                  if (this.isProductAlreadyExists(val,this.state.product_list)===true){
                
                    message.error("Product exists")
                  
                  }else{
  
                    var intobj={
                      'key':this.state.product_list.length+1,
                      'product_name':val,
                    }
    
                    this.setState({product_list: [...this.state.product_list,intobj]});
                    message.info("item added")
                    this.setState({selected_product:''})
  
                  }
                
                
                }} 
                showSearch
                optionFilterProp="children"
                >
                  {this.state.products.map(
                    (prdt)=>(
                      <Option value={prdt.product_name}>{prdt.product_name}</Option>
                    ))}
                </Select>
          </FormItem>

          <Divider></Divider>


             {
                this.state.product_list.length>0?
                <div >
                  {
                    this.state.product_list.map((item)=>{
                      return (
                      <p>{item.key} | {item.product_name} &nbsp;&nbsp;
                      <Popover content={<p>Click here to delete item</p>} title="Delete">
                      <Popconfirm title="Sure to delete?" onConfirm={() => {
                        this.setState({ product_list:  [...this.state.product_list.filter(todo => todo.key
                          !==item.key)]});
                      }}>
                        <DeleteOutlined style={{color:'red'}}/>
                      </Popconfirm>
                      </Popover>
                      
                      </p>)
                    })
                  }

                </div>
                :
                null
              }

            <Divider></Divider>     

        <FormItem>
            <Button  type="primary" htmlType="submit">Update</Button>
        </FormItem>
        </Form>
            
        </div>
        )
    }
}

export default SupplierDetail; 