import React from 'react';
import HealthCenterForm from '../components/HealthCenterForm'
import axios from 'axios'
import { Table, Input, Button,Collapse,Popover,Popconfirm,message,Spin,Modal } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon from '@ant-design/icons';
import { SearchOutlined,DeleteOutlined,FundViewOutlined,LoadingOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import * as serverconfig from '../../serverconn'
import HUbQrCodeView from './HubQRCodeView'

var CryptoJS = require("crypto-js");

const { Panel } = Collapse;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

function callback(key) {
  console.log(key);
}


var token= ''
var sacco= ''

class Hub_HealthCenterList extends React.Component {
  state = {
    searchText: '',
    searchedColumn: '',
    healthcentres: [],
    datarequested:true,
    selected_record:{}


  };

  componentDidMount(){
    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
    }else{
       sacco= ''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(serverconfig.backendserverurl+`/api/healthcentres/?collection_hub=${localStorage.getItem("coodinated_hub")}`)
    .then(res => {
        this.setState({
            healthcentres:res.data
        })
        this.setState({datarequested:false})

    })
}

getColumnSearchProps = dataIndex => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    <div style={{ padding: 8 }}>
      <Input
        ref={node => {
          this.searchInput = node;
        }}
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
      <Button
        type="primary"
        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        icon={<SearchOutlined />}
        size="small"
        style={{ width: 90, marginRight: 8 }}
      >
        Search
      </Button>
      <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
        Reset
      </Button>
    </div>
  ),
  filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilter: (value, record) =>
    record[dataIndex]
      .toString()
      .toLowerCase()
      .includes(value.toLowerCase()),
  onFilterDropdownVisibleChange: visible => {
    if (visible) {
      setTimeout(() => this.searchInput.select());
    }
  },
  render: text =>
    this.state.searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ) : (
      text
    ),
});

handleSearch = (selectedKeys, confirm, dataIndex) => {
  confirm();
  this.setState({
    searchText: selectedKeys[0],
    searchedColumn: dataIndex,
  });
};

handleReset = clearFilters => {
  clearFilters();
  this.setState({ searchText: '' });
};

    //////////////////////////////////////////////////
  //handle delete here
  handleDelete = id => {
    axios.delete(`${serverconfig.backendserverurl}/api/healthcentres/${id}`).then(res =>{
      window.location.reload(false)
      message.info("successfully deleted") 
     }
     );
  };

  render() {
    const columns = [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'id',
        ...this.getColumnSearchProps('name'),
      },
     
      {
        title: 'Collection hub',
        dataIndex: 'hub_name',
        key: 'id',
        ...this.getColumnSearchProps('hub_name'),
      },
      {
        title: 'Contact person name',
        dataIndex: 'contactperson_name',
        key: 'id',
      },
      {
        title: 'Contact person tel',
        dataIndex: 'contactperson_phone',
        key: 'id',
      },
      {
        title: 'Address latitude',
        dataIndex: 'location_latitude',
        key: 'id',
      },
      {
        title: 'Address longitude',
        dataIndex: 'location_longitude',
        key: 'id',
      },
      {
        title: 'Action',
        dataIndex: 'id',
        key: 'id',
        render: (text,record) =>
        <p>
        <Popover content={<p>Click here to edit,view details</p>} title="Edit,View">
         <Link to={`/healthcenters/${text}`}><FundViewOutlined style={{color:'blue'}}/></Link>
        </Popover>
        &nbsp;&nbsp;&nbsp;
        <Popover content={<p>Print/view</p>} title="View,print QR code">
         <FundViewOutlined style={{color:'green'}} 
          onClick={()=>{
          this.setState({show_qrModel:true})
          this.setState({selected_record:record})
          
          }} />
        </Popover>


        &nbsp;&nbsp;&nbsp;
        <Popover content={<p>Click here to delete client</p>} title="Delete">
        <Popconfirm title="Sure to delete?" onConfirm={() => this.handleDelete(text)}>
          <DeleteOutlined style={{color:'red'}}/>
         </Popconfirm>
        </Popover>
        </p>,
      }

    ];

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{
      return(
        <div>
            <Collapse defaultActiveKey={['1']} onChange={this.callback}>
            <Panel header="ALL HEALTH CENTERS" key="1">
            <Table 
            columns={columns} 
            scroll={{ x: 1000 }}
            pagination={{showQuickJumper:true,showSizeChanger:true }}
            dataSource={this.state.healthcentres}
            bordered/>
             </Panel>
             </Collapse>


            <Modal              
                visible={this.state.show_qrModel}
                title="QR Code display"
                width={600}
                onCancel={()=>{this.setState({show_qrModel:false})}}

                footer={[
                  <Button key="back" onClick={()=>{this.setState({show_qrModel:false})}}>
                    Cancel
                  </Button>
                  ]}
                  >
                      <HUbQrCodeView 
                      selected_record={this.state.selected_record}
                      facility_type={'Healthcenter'}
                      facility_name={this.state.selected_record.name}
                      title={"QR Code for "+this.state.selected_record.name}
                      />
            </Modal>

        </div>
    )

    }

   
  }
}

export default Hub_HealthCenterList; 
