import React from 'react';
import { GoogleMap, Polyline, LoadScript,Marker,InfoWindow } from '@react-google-maps/api';
import * as primarycolor from '../../primarycolor'
import SportsMotorsportsIcon from '@mui/icons-material/SportsMotorsports';
import * as map_apikey from '../../map_api_key'

const PolylineMap = ({ initialCoordinates }) => {
  const [coordinates, setCoordinates] = React.useState(initialCoordinates);

  const mapContainerStyle = {
    width: '100%',
    height: '400px',
  };

  const center = {
    lat: 0.3476, // Kampala latitude
    lng: 32.5825, // Kampala longitude
  };

  const defaultZoom = 12; // Adjust the zoom level as needed

  const [selectedMarker, setSelectedMarker] = React.useState(null);

  const handleMarkerClick = (marker) => {
    setSelectedMarker(marker);
  };

 
  return (
    <LoadScript googleMapsApiKey={'AIzaSyD7DhyWsX8qUNdEri4hGRjDOx566po_UpA'}>

      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        center={center}
        zoom={defaultZoom} >

         {
            coordinates.length>0?
            coordinates.map((item)=>(
              <Marker  
              //icon={<SportsMotorsportsIcon style={{color:primarycolor.primarycolor,fontSize: 30}} />}
              title={item.RegisteredPhoneNumber1+" @ "+String(item.location_address)+" ("+String(item.location_latitude)+" , "+String(item.location_longitude)+" )"} 
              position={{ lat: Number(item.location_latitude), lng: Number(item.location_longitude) }}  />
            ))
            :
            null
    
          }

      </GoogleMap>
      </LoadScript>
  );
};

export default PolylineMap;