import React from 'react';
import axios from 'axios';
import { Card, Button, Avatar,Modal,Form, Input,Select,Spin,Image,Result, message,Switch,Tabs } from 'antd';
import { UserOutlined,LoadingOutlined } from '@ant-design/icons';
import { PlusOutlined } from '@ant-design/icons';
import { Drawer,Divider } from 'antd';
import * as serverconfig from '../../serverconn'
import {
  Col,
  Row,
} from 'reactstrap';
import PhoneInput from 'react-phone-number-input'


var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const { Option } = Select;
const { Meta } = Card;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const { TabPane } = Tabs;

var token= ''
var bizuserid= ''

class CompanyProfile extends React.Component{

    state={
        profile: {},
        datarequested:true,
        system_name:'',
        company_logo:null,
        company_logo_file:'',
        company_email:'',
        RegisteredPhoneNumber1:'',

        box_number:'',
        send_smsalerttoagents_on_clientorders:false,

        send_sms_alerttoadmin_on_clientorders:false,
        send_email_alerttoadmin_on_clientorders:false,


        send_sms_alerttoadmin_on_deliveries:false,
        send_email_alerttoadmin_on_deliveries:false,


        send_sms_alerttoadmin_on_pickups:false,
        send_email_alerttoadmin_on_pickups:false,

        maximum_distanceinmeters_fordeliveryverification:0,
        smsCost:0,
        smsbalance:0,
        ismobilemoneypayment_enabled:false,

        manual_date_enabled:false

      
    }


    componentDidMount(){
      if(localStorage.getItem("token")){
         token= localStorage.getItem("token")
      }else{
         token= ''
      }


      axios.defaults.headers={
        "Content-Type":"application/json",
        Authorization:`Token ${token}`
      }

        axios.get(`${serverconfig.backendserverurl}/api/app_profile/${1}`)
        .then(res => {  
            this.setState({
              profile: res.data
            })
            console.log(res.data)

            this.setState({system_name:res.data.system_name})
            this.setState({company_email:res.data.company_email})
            this.setState({RegisteredPhoneNumber1:res.data.RegisteredPhoneNumber1})

            this.setState({box_number:res.data.box_number})
            this.setState({company_logo_file:res.data.company_logo})

            this.setState({send_smsalerttoagents_on_clientorders:res.data.send_smsalerttoagents_on_clientorders})

            this.setState({send_sms_alerttoadmin_on_clientorders:res.data.send_sms_alerttoadmin_on_clientorders})
            this.setState({send_email_alerttoadmin_on_clientorders:res.data.send_email_alerttoadmin_on_clientorders})

            this.setState({send_sms_alerttoadmin_on_deliveries:res.data.send_sms_alerttoadmin_on_deliveries})
            this.setState({send_email_alerttoadmin_on_deliveries:res.data.send_email_alerttoadmin_on_deliveries})

            this.setState({send_sms_alerttoadmin_on_pickups:res.data.send_sms_alerttoadmin_on_pickups})
            this.setState({send_email_alerttoadmin_on_pickups:res.data.send_email_alerttoadmin_on_pickups})


            this.setState({maximum_distanceinmeters_fordeliveryverification:res.data.maximum_distanceinmeters_fordeliveryverification})
            this.setState({smsCost:res.data.smsCost})
            this.setState({smsbalance:res.data.smsbalance})

            this.setState({ismobilemoneypayment_enabled:res.data.ismobilemoneypayment_enabled})

            this.setState({manual_date_enabled:res.data.manual_date_enabled})
            
            this.setState({datarequested:false})

        })


    }

    handleFormSubmit=(event) =>{
      this.setState({datarequested:true})
    
      let form_data = new FormData();
  
      this.state.company_logo==null?
      console.log("No company_logo file")
      :
      form_data.append('company_logo', this.state.company_logo,this.state.company_logo?this.state.company_logo.name:"");
  
      form_data.append('system_name', this.state.system_name);
      form_data.append('company_email', this.state.company_email);
      form_data.append('RegisteredPhoneNumber1', this.state.RegisteredPhoneNumber1);
      form_data.append('box_number', this.state.box_number);
      form_data.append('send_smsalerttoagents_on_clientorders', this.state.send_smsalerttoagents_on_clientorders);


      form_data.append('send_sms_alerttoadmin_on_clientorders', this.state.send_sms_alerttoadmin_on_clientorders);
      form_data.append('send_email_alerttoadmin_on_clientorders', this.state.send_email_alerttoadmin_on_clientorders);

      form_data.append('send_sms_alerttoadmin_on_deliveries', this.state.send_sms_alerttoadmin_on_deliveries);
      form_data.append('send_email_alerttoadmin_on_deliveries', this.state.send_email_alerttoadmin_on_deliveries);

      form_data.append('send_sms_alerttoadmin_on_pickups', this.state.send_sms_alerttoadmin_on_pickups);
      form_data.append('send_email_alerttoadmin_on_pickups', this.state.send_email_alerttoadmin_on_pickups);

      form_data.append('maximum_distanceinmeters_fordeliveryverification', this.state.maximum_distanceinmeters_fordeliveryverification);
      form_data.append('smsCost', this.state.smsCost);
      form_data.append('smsbalance', this.state.smsbalance);
      form_data.append('ismobilemoneypayment_enabled', this.state.ismobilemoneypayment_enabled);
      form_data.append('manual_date_enabled', this.state.manual_date_enabled);

      axios.put(`${serverconfig.backendserverurl}/api/app_profile/${1}/`,form_data, {
        headers: {
        'content-type': 'multipart/form-data'
        }
      })
      .then(res =>{
        console.log(res.data.message)
        this.setState({datarequested:false})
        this.setState({message:res.data.message})
        this.setState({datasubmittedsuccessfully:true})

      })       
     
      }


    render(){

      if(this.state.datarequested===true){
        return(
          <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
              <Spin size="large">
              <div className="content">
                <h3>Umoja couriers</h3>
              </div>
               </Spin> 
             </div>
        )
  
      }else{
        return(
          <div>

            <Tabs defaultActiveKey="1" onChange={()=>{}}>
                <TabPane tab="Main profile settings" key="001" >

                {
            this.state.datasubmittedsuccessfully===true?
            <Card>
            <Result
            status="success"
            subTitle={this.state.message}
            extra={[
              <Button  type='primary' onClick={()=>{window.location.reload(false)}}>Finish</Button>,
            ]}
            />
            </Card>
            :
            <Card 
            style={{padding:10}}
            hoverable>
          <Form
                onFinish={(event) => this.handleFormSubmit(event)}
                >
                   <h3>Main profile settings</h3>

                    <FormItem label="System Name">
                        <Input name="system_name"  placeholder="Company name." value={this.state.system_name} onChange={(val)=>{this.setState({system_name:val.target.value})}} />
                    </FormItem>


                    <div style={{display:'flex',flexDirection:'row'}}>

                    <FormItem label="Logo">
                        <Input name="companylogo" type="file" accept="image/png, image/jpeg" placeholder="Company logo" 
                        
                        onChange={(e) =>{
                        if(e.target.files[0]){
                          this.setState({
                            company_logo_file: URL.createObjectURL(e.target.files[0])
                          })
                        }
                        this.setState({ company_logo: e.target.files[0]})

                        } }/>
                    </FormItem> 

                    {this.state.company_logo!=null || this.state.company_logo_file!=""?
                      <Image
                      width={50}
                      height={50}
                      src={this.state.company_logo_file}    
                      />
                      :
                      null
                      }

                    </div>

                    <FormItem label="Company Email">
                        <Input name="companyemail" type="email"  placeholder="company@email.com" value={this.state.company_email} onChange={(val)=>{this.setState({company_email:val.target.value})}} />
                    </FormItem> 

    
                    <FormItem label="Phone Number one">
                    <PhoneInput
                            countrySelectProps={{ unicodeFlags: true }}
                            defaultCountry="UG"
                            placeholder="Enter phone number"
                            value={this.state.RegisteredPhoneNumber1} onChange={(val)=>{this.setState({RegisteredPhoneNumber1:val})}}/>
                    </FormItem> 

    
                    <FormItem label="Box number">
                        <Input placeholder="Box number here" value={this.state.box_number} onChange={(val)=>{this.setState({box_number:val.target.value})}} />
                    </FormItem>

                    <h3>SMS</h3>
                    <FormItem label="sms balance">
                        <Input placeholder="smsbalance" 
                        value={this.state.smsbalance} 
                        onChange={(val)=>{this.setState({smsbalance:val.target.value})}} />
                    </FormItem>

                    <FormItem label="sms cost">
                        <Input placeholder="smsCost" 
                        value={this.state.smsCost} 
                        onChange={(val)=>{this.setState({smsCost:val.target.value})}} />
                    </FormItem>


                  <h3>Alerts</h3>
                  <FormItem name="send_smsalerttoagents_on_clientorders" label="send_smsalerttoagents_on_clientorders?" >
                    <Switch
                        checked={this.state.send_smsalerttoagents_on_clientorders}
                        onChange={val=>{this.setState({send_smsalerttoagents_on_clientorders:!this.state.send_smsalerttoagents_on_clientorders})}}
                        checkedChildren="ON"
                        unCheckedChildren="OFF"
                        />
                  </FormItem>

                  <FormItem name="send_sms_alerttoadmin_on_clientorders" label="send_sms_alerttoadmin_on_clientorders?" >
                    <Switch
                        checked={this.state.send_sms_alerttoadmin_on_clientorders}
                        onChange={val=>{this.setState({send_sms_alerttoadmin_on_clientorders:!this.state.send_sms_alerttoadmin_on_clientorders})}}
                        checkedChildren="ON"
                        unCheckedChildren="OFF"
                        />
                  </FormItem>

                  <FormItem name="send_email_alerttoadmin_on_clientorders" label="send_email_alerttoadmin_on_clientorders?" >
                    <Switch
                        checked={this.state.send_email_alerttoadmin_on_clientorders}
                        onChange={val=>{this.setState({send_email_alerttoadmin_on_clientorders:!this.state.send_email_alerttoadmin_on_clientorders})}}
                        checkedChildren="ON"
                        unCheckedChildren="OFF"
                        />
                  </FormItem>


                  <FormItem name="send_sms_alerttoadmin_on_deliveries" label="send_sms_alerttoadmin_on_deliveries?" >
                    <Switch
                        checked={this.state.send_sms_alerttoadmin_on_deliveries}
                        onChange={val=>{this.setState({send_sms_alerttoadmin_on_deliveries:!this.state.send_sms_alerttoadmin_on_deliveries})}}
                        checkedChildren="ON"
                        unCheckedChildren="OFF"
                        />
                  </FormItem>


                  <FormItem name="send_email_alerttoadmin_on_deliveries" label="send_email_alerttoadmin_on_deliveries?" >
                    <Switch
                        checked={this.state.send_email_alerttoadmin_on_deliveries}
                        onChange={val=>{this.setState({send_email_alerttoadmin_on_deliveries:!this.state.send_email_alerttoadmin_on_deliveries})}}
                        checkedChildren="ON"
                        unCheckedChildren="OFF"
                        />
                  </FormItem>


                  <FormItem name="send_sms_alerttoadmin_on_pickups" label="send_sms_alerttoadmin_on_pickups?" >
                    <Switch
                        checked={this.state.send_sms_alerttoadmin_on_pickups}
                        onChange={val=>{this.setState({send_sms_alerttoadmin_on_pickups:!this.state.send_sms_alerttoadmin_on_pickups})}}
                        checkedChildren="ON"
                        unCheckedChildren="OFF"
                        />
                  </FormItem>


                  <FormItem name="send_email_alerttoadmin_on_pickups" label="send_email_alerttoadmin_on_pickups?" >
                    <Switch
                        checked={this.state.send_email_alerttoadmin_on_pickups}
                        onChange={val=>{this.setState({send_email_alerttoadmin_on_pickups:!this.state.send_email_alerttoadmin_on_pickups})}}
                        checkedChildren="ON"
                        unCheckedChildren="OFF"
                        />
                  </FormItem>

                  <h3>Agent umoja delivery control</h3>

                    <FormItem label="maximum distance in meters(Between Destination and agent) for umoja delivery verification">
                        <Input placeholder="maximum_distanceinmeters_fordeliveryverification" 
                        value={this.state.maximum_distanceinmeters_fordeliveryverification} 
                        onChange={(val)=>{this.setState({maximum_distanceinmeters_fordeliveryverification:val.target.value})}} />
                    </FormItem>

                  <h3>Payment modes </h3>
                  <FormItem name="ismobilemoneypayment_enabled" label="is mobile money payment enabled?" >
                    <Switch
                        checked={this.state.ismobilemoneypayment_enabled}
                        onChange={val=>{this.setState({ismobilemoneypayment_enabled:!this.state.ismobilemoneypayment_enabled})}}
                        checkedChildren="ON"
                        unCheckedChildren="OFF"
                        />
                  </FormItem>

                  <h3>Dates </h3>
                  <FormItem name="manual_date_enabled" label="Manual date enabled?" >
                    <Switch
                        checked={this.state.manual_date_enabled}
                        onChange={val=>{this.setState({manual_date_enabled:!this.state.manual_date_enabled})}}
                        checkedChildren="ON"
                        unCheckedChildren="OFF"
                        />
                  </FormItem>


                  <FormItem>
                      <Button type="primary" htmlType="submit" >Update Profile</Button>
                  </FormItem>
                  
               </Form>
          </Card>
          }
          
          </TabPane>
             
            </Tabs>

          </div>
      )}
       
    }
}

export default CompanyProfile; 