import React from 'react';
import { Form, Input,Popover, Button,Select,DatePicker,Spin,Image, Card,Divider,Result, message,Modal,Popconfirm } from 'antd';
import axios from 'axios'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import moment from 'moment';
import * as serverconfig from '../../serverconn'
import {
  Col,
  Row,
} from 'reactstrap';
import { UserOutlined, LockOutlined, LoadingOutlined,MailOutlined,PlusCircleFilled,CloseCircleFilled,CheckCircleFilled,WarningFilled } from '@ant-design/icons';
import uuid from 'react-uuid'
import Resizer from "react-image-file-resizer";

var CryptoJS = require("crypto-js");


const FormItem=Form.Item;
const { Option } = Select;
const dateFormat = 'DD/MM/YYYY';
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

var token= ''
var instance_id= ''
var bizuserid= ''
var username= ''


class DeliveryAgentForm extends React.Component {

  state = {
    date:moment().format(dateFormat).toString(),
    accounttypes:[],
    idtypes:[],
    sur_name:'' ,  
    middle_name:'',
    given_name:'',
    nationality:'',
    ID_type:'',
    ID_number:'',
    date_Of_Birth:'',
    Email_Address:'',
    P_O_Box:'',
    Area_Of_Residence:'',
    District:'',
    RegisteredPhoneNumber1:'',
    RegisteredPhoneNumber2:'',
    next_of_kin:'',
    Next_Of_Kin_Physical_Address:'',
    relationship:'',
    Photo:null,
    Signature:null,
    Thumbprint:null,

    Date_Of_Joining:'',
    account_type:'',
    date_of_appointment:'',
    status:'',
    staff_number:'',
    reference_one:'',
    Referee1PhoneNumber:'',
    datarequested:true,
    accountnumber:'',
    photofile:null,
    signaturefile:null,
    thumbprintfile:null,
    marital_status:'',
    own_residence:'',
    recommender:'',
    gender:'',
    any_other_account:'',
    other_account_number:'',
    other_bank:'',
    shares:0,
    title:'',
    datasubmittedsuccessfully:false,
    instance_idpackageobj:{},
    members:[],
    companyprofile:{},

    occupation:'',
    postaladdress:'',
    homecelllocaltion:'',
    cell:'',
    cellleader:'',
    nameofemployer_business:'',

    addressofemployer_business:'',
    refree_institutionalmember_name:'',
    refree_institutionalmember_contact:'',
    refree_cellleader_name:'',
    refree_cellleader_contact:'',
    next_of_kin_contact:'',

    child_account_school:'',
    child_account_school_class:'',

    membertype:'senior',
    pysical_form:null,
    saving_period:'',

    id_expirydate:'',
    ugdistricts:[],
    cellleader_contact:'',
    id_valid:false,
    age_valid:false,
    accounttypeobject:{},
    idtypeobject:{},

    otheraccountslist:[],
    other_accounts_model_visible:false,

    other_account_type:'',
    other_account_bank_or_telecom:'',
    other_account_name:'',
    other_account_number_contact:'',
    milk_selling_price:0,

    collectionhubs: [],
    selected_hub:'',

    status_message:''

  }

  componentDidMount(){

    if(localStorage.getItem("instance_id")){
       instance_id= localStorage.getItem("instance_id") 
    }else{
       instance_id= ''
    }

    if(localStorage.getItem("bizuserid")){
      bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
    }else{
      bizuserid= ''
    }

    if(localStorage.getItem("username")){
      username= CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)
    }else{
      username= ''
    }


    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }


    //get the instance_id package here
    axios.get(serverconfig.backendserverurl+`/api/ugdistricts/`)
    .then(res => {
      this.setState({
        ugdistricts:res.data
      })
    })

    axios.get(serverconfig.backendserverurl+`/api/collectionhubs`)
    .then(res => {
        this.setState({
            collectionhubs:res.data
        })
        this.setState({datarequested:false})
    })
}

  //imgae resizer
  resizeFile = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      300,
      400,
      "JPEG",
      50,
      0,
      (uri) => {
        resolve(uri);
      },
      "base64"
    );
  });


  //convert back to file
  dataURIToBlob = (dataURI) => {
    const splitDataURI = dataURI.split(",");
    const byteString =
      splitDataURI[0].indexOf("base64") >= 0
        ? atob(splitDataURI[1])
        : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(":")[1].split(";")[0];
    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);
    return new Blob([ia], { type: mimeString });
  };


  //submit button pressed
  handleFormSubmit=(event) =>{

        if (this.state.RegisteredPhoneNumber1===''){
          message.info("Phone Number missing")
        }else{

        this.setState({datarequested:true})

        let form_data = new FormData();
        form_data.append('date_of_registration', this.state.date);
        form_data.append('first_name', this.state.sur_name);
        form_data.append('second_name', this.state.given_name);
        form_data.append('Nationality', this.state.nationality);  
        form_data.append('IDNumber', this.state.ID_number);
        form_data.append('AreaOfResidence', this.state.Area_Of_Residence);
        form_data.append('District', this.state.District);
        form_data.append('RegisteredPhoneNumber1', this.state.RegisteredPhoneNumber1);
            
        form_data.append('gender', this.state.gender);
        form_data.append('created_by', username);
        form_data.append('collection_hub', this.state.selected_hub);

        this.state.Photo==null?
        console.log("No profile file")
        :
        form_data.append('Photo', this.state.Photo, this.state.Photo?this.state.Photo.name:"");
        
        axios.post(serverconfig.backendserverurl+'/customqueries/manual_agentregistration', form_data, {
          headers: {
            'content-type': 'multipart/form-data'
          }
        })
        .then(res =>{
            console.log(res.data)
            var return_agentid=res.data.return_agent
            var message=res.data.message

            this.setState({status_message:message})

            //methiods to show completed
            this.setState({datarequested:false})
            this.setState({datasubmittedsuccessfully:true})

        })
        .catch(error => console.log(error))     
        //auto reuest endds    

      }

  }


  handlesur_nameChange= (e) => this.setState({ sur_name: e.target.value});
  handlemiddle_nameChange= (e) => this.setState({ middle_name: e.target.value});
  handlegiven_nameChange= (e) => this.setState({ given_name: e.target.value});
  handlenationalityChange= (e) => this.setState({ nationality: e.target.value});

  handletitleChange= (e) => this.setState({ title: e});

  handleID_numberChange= (e) => this.setState({ ID_number: e.target.value});
  handledate_Of_BirthChange= (date, dateString) =>{
    this.setState({ date_Of_Birth: dateString});
  } 

  handleEmail_AddressChange= (e) => this.setState({ Email_Address: e.target.value});
  handleP_O_BoxChange= (e) => this.setState({ P_O_Box: e.target.value});
  handleArea_Of_ResidenceChange= (e) => this.setState({ Area_Of_Residence: e.target.value});
  handleDistrictChange= (e) => this.setState({ District: e.target.value});
  handleRegisteredPhoneNumber1Change= (e) => this.setState({ RegisteredPhoneNumber1: e});

  handleRegisteredPhoneNumber2Change= (e) => this.setState({ RegisteredPhoneNumber2: e});
  handlenext_of_kinChange= (e) => this.setState({ next_of_kin: e.target.value});
  handleNext_Of_Kin_Physical_AddressChange= (e) => this.setState({ Next_Of_Kin_Physical_Address: e.target.value});
  handlerelationshipChange= (e) => this.setState({ relationship: e.target.value});
  

  handlePhotoChange= async(e) =>{
    
    //this.setState({ Photo: e.target.files[0]});

    if(e.target.files[0]){
      this.setState({
        photofile: URL.createObjectURL(e.target.files[0])
      })
    }

    const file = e.target.files[0];
    //send to resizer
    const image = await this.resizeFile(file);
    const newFile = this.dataURIToBlob(image);
    this.setState({ Photo: newFile});
    console.log(newFile);

  } 

 

  handleDate_Of_JoiningChange= (date, dateString) => this.setState({ Date_Of_Joining: dateString});
  
  handledate_of_appointmentChange= (date, dateString) => this.setState({ date_of_appointment: dateString});

  handlestatusChange= (e) => this.setState({ status: e});
  handlemarital_statusChange= (e) => this.setState({ marital_status: e});
  handleown_residenceChange= (e) => this.setState({ own_residence: e});

  handlestaff_numberChange= (e) => this.setState({ staff_number: e.target.value});
  handlereference_oneChange= (e) => this.setState({ reference_one: e.target.value});
  handleReferee1PhoneNumberChange= (e) => this.setState({ Referee1PhoneNumber: e});

  handleaccountnumberChange= (e) => this.setState({ accountnumber: e.target.value});
  handlerecommenderChange= (e) => this.setState({ recommender: e.target.value});
   handlegenderChange= (e) => this.setState({ gender: e});

  handleother_account_numberChange= (e) => this.setState({ other_account_number: e.target.value});
  handleany_other_accountChange= (e) => this.setState({ any_other_account: e});
  handleother_bankChange= (e) => this.setState({ other_bank: e.target.value});
  handlesharesChange= (e) => this.setState({ shares: e.target.value});



  onBlur=()=> {
    console.log('blur');
  }
  
   onFocus=()=>{
    console.log('focus');
  }
  
  onSearch=(val)=> {
    console.log('search:', val);
  }


  render(){
    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )
    }else{

      if (this.state.datasubmittedsuccessfully===true){
        return(
            <Card>
            <Result
            status="success"
            title="Agent creation status "
            subTitle={this.state.status_message}
            extra={[
              <Button  type='primary' onClick={()=>{window.location.reload(false)}}>Finish</Button>,
            ]}
            />
            </Card>
        );

      }else{


            return (
              <div>
                <Form
                    onFinish={(event) => this.handleFormSubmit(event)}
                >

                  <FormItem label="First Name"
                      name="surname"
                      rules={[
                        {
                          required: true,
                          message: 'Please input first name',
                        },
                      ]}          
                  >
                    <Input  placeholder="Put a name here." value={this.state.sur_name} onChange={this.handlesur_nameChange}       />
                  </FormItem>
        
       
                  <FormItem label="Second Name"
                      name="givenname"
                      rules={[
                        {
                          required: true,
                          message: 'Please input second name',
                        },
        
                      ]}           
                  >
                    <Input  placeholder="Put a name here." value={this.state.given_name} onChange={this.handlegiven_nameChange} />
                  </FormItem>
      
                  <FormItem label="Gender"
                     name="gender"
                     rules={[
                       {
                         required: true,
                         message: 'Please select',
                       },
                     ]}           
                  
                  >
                      <Select placeholder="Gender" 
                      style={{ width: 120 }} 
                      value={this.state.gender} 
                      onChange={this.handlegenderChange} 
                      showSearch
                      optionFilterProp="children"
                      onFocus={this.onFocus}
                      onBlur={this.onBlur}
                      onSearch={this.onSearch}
                      >
                      <Option value="Male">Male</Option>
                      <Option value="Female">Female</Option>
                      </Select>
                  </FormItem>
      
        
                  <FormItem label="Nationality"
                      name="nationality"
                      rules={[
                        {
                          required: true,
                          message: 'Please input nationality',
                        },
                      ]}            
                  >
                    <Input  placeholder="Nationality." value={this.state.nationality} onChange={this.handlenationalityChange} />
                  </FormItem>
                  
                  
                  <FormItem label="ID Number"
                  name="ID_number"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter ID Number',
                    },
                  ]}                   
                  
                  >
                  <Input  placeholder="ID Number." value={this.state.ID_number} onChange={this.handleID_numberChange} />
                  </FormItem>
              
                      
                  <div style={{display:'flex',flexDirection:'row'}}>
                  <FormItem label="Photo" style={{marginRight:3}}>
                        <Input type="file" accept="image/png, image/jpeg" placeholder="Member Photo"  onChange={this.handlePhotoChange}/>
                  </FormItem> 
      
                  {this.state.Photo?
                  <Image
                  width={50}
                  height={50}
                  src={this.state.photofile}    
                  />
                  :
                  null
                  }
      
                  </div>
        
                  <h3>Contact Section</h3>
                 
                  <FormItem label="Residential (Physical) Address"
                      name="areaofresidence"
                      rules={[
                        {
                          required: true,
                          message: 'Please enter residence',
                        },
                      ]}           
                  >
                    <Input placeholder="Area of residence." value={this.state.Area_Of_Residence} onChange={this.handleArea_Of_ResidenceChange} />
                  </FormItem>
        
                  <FormItem label="District"
                     name="district"
                     rules={[
                       {
                         required: true,
                         message: 'Please enter district of residence',
                       },
                     ]}           
                  >
                    <Select
                     placeholder="Ug District"
                      style={{  }} 
                      value={this.state.District}
                       onChange={(val)=>{this.setState({District:val})}} 
                       
                       showSearch
                       optionFilterProp="children"
                       onFocus={this.onFocus}
                       onBlur={this.onBlur}
                       onSearch={this.onSearch}
                       
                       >
                      {this.state.ugdistricts.map(
                        (dist)=>(
                          <Option value={dist.district_name}>{dist.district_name} | {dist.region}</Option>
                        ))}
                    </Select>
                  </FormItem>
        
                  <FormItem label= { <h6 style={{display:'flex'}} >* Phone number</h6>}                       
                  >
                    <Input placeholder="Phone contact." value={this.state.RegisteredPhoneNumber1} onChange={(val)=>{this.setState({RegisteredPhoneNumber1:val.target.value})}}  maxLength={10}/>

                 </FormItem> 

                 <FormItem label="Attach collection hub"
                    name="hub"
                    rules={[
                      {
                        required: true,
                        message: 'Please select hub',
                      },
                    ]}  
                  
                  >
                      <Select 
                      placeholder="Hub"
                      style={{ width: 200 }} 
                      value={this.state.selected_hub} 
                      onChange={(val)=>{this.setState({selected_hub:val})}}

                      showSearch
                      optionFilterProp="children"
                      onFocus={this.onFocus}
                      onBlur={this.onBlur}
                      onSearch={this.onSearch}
                      filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }                     
                      >
                        {this.state.collectionhubs.map(
                          (item)=>(
                            <Option value={item.id}>{item.name}</Option>
                          ))}
                      </Select>
                  </FormItem>
                  <h3>Note: Password is last four digits of phone contact and username is phone contact.</h3>

                  <FormItem >
                    <Button  type='primary' htmlType="submit">Submit</Button>
                  </FormItem>
      
                </Form>
              </div>
            );

          }

      }

    }
    
  }



export default DeliveryAgentForm;
