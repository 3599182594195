import React from 'react';
import ProductForm from '../components/ProductForm'
import axios from 'axios'
import { Table, Input, Button,Collapse,Popover,Popconfirm,message,Modal,Image,Form,Select,Switch } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon from '@ant-design/icons';
import { SearchOutlined,DeleteOutlined,FundViewOutlined } from '@ant-design/icons';
import QRCode from 'react-qr-code';
import { Link } from 'react-router-dom';
import * as serverconfig from '../../serverconn'
import Resizer from "react-image-file-resizer";

const { Panel } = Collapse;
const FormItem=Form.Item;
const { Option } = Select;
const { TextArea } = Input;


function callback(key) {
  console.log(key);
}

class ProductList extends React.Component {
  state = {
    searchText: '',
    searchedColumn: '',
    products: [],
    productunits:[],
    currenteditablecolumn: {},
    showeditableform:false,

    product_name:0,
    unit:'',
    sellingprice:0,
    buyingprice:0,
    discount_percentage:0,
    product_online:false,
    description:'',
    Photo:null,
    photofile:null,

    productcategories:[],
    product_category:''

  };

  componentDidMount(){
    axios.get(serverconfig.backendserverurl+`/api/products/`)
    .then(res => {
        this.setState({
            products:res.data
        })
    })

    axios.get(serverconfig.backendserverurl+`/api/productunits/`)
    .then(res => {
        this.setState({
          productunits:res.data
        })
    })


    axios.get(serverconfig.backendserverurl+`/api/productcategories/`)
    .then(res => {
        this.setState({
          productcategories:res.data
        })
    })


    
}

       //////////////////////////////////////////////////
  //handle delete here
  handleDelete = id => {
    axios.delete(`${serverconfig.backendserverurl}/api/products/${id}`).then(res =>{
      window.location.reload(false)
      message.info("successfully deleted") 
     }
     );
  };


getColumnSearchProps = dataIndex => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    <div style={{ padding: 8 }}>
      <Input
        ref={node => {
          this.searchInput = node;
        }}
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
      <Button
        type="primary"
        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        icon={<SearchOutlined />}
        size="small"
        style={{ width: 90, marginRight: 8 }}
      >
        Search
      </Button>
      <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
        Reset
      </Button>
    </div>
  ),
  filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilter: (value, record) =>
    record[dataIndex]
      .toString()
      .toLowerCase()
      .includes(value.toLowerCase()),
  onFilterDropdownVisibleChange: visible => {
    if (visible) {
      setTimeout(() => this.searchInput.select());
    }
  },
  render: text =>
    this.state.searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ) : (
      text
    ),
});

handleSearch = (selectedKeys, confirm, dataIndex) => {
  confirm();
  this.setState({
    searchText: selectedKeys[0],
    searchedColumn: dataIndex,
  });
};

handleReset = clearFilters => {
  clearFilters();
  this.setState({ searchText: '' });
};

handlePhotoChange= async(e) =>{
  //this.setState({ Photo: e.target.files[0]});

  if(e.target.files[0]){
    this.setState({
      photofile: URL.createObjectURL(e.target.files[0])
    })
  }

  const file = e.target.files[0];
  //send to resizer
  const image = await this.resizeFile(file);
  const newFile = this.dataURIToBlob(image);
  this.setState({ Photo: newFile});
  console.log(newFile);

} 



//imgae resizer
resizeFile = (file) =>
new Promise((resolve) => {
  Resizer.imageFileResizer(
    file,
    300,
    400,
    "JPEG",
    50,
    0,
    (uri) => {
      resolve(uri);
    },
    "base64"
  );
});

//convert back to file
 dataURIToBlob = (dataURI) => {
  const splitDataURI = dataURI.split(",");
  const byteString =
    splitDataURI[0].indexOf("base64") >= 0
      ? atob(splitDataURI[1])
      : decodeURI(splitDataURI[1]);
  const mimeString = splitDataURI[0].split(":")[1].split(";")[0];
  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);
  return new Blob([ia], { type: mimeString });
};



  render() {
    const columns = [
      {
        title: 'Product Name',
        dataIndex: 'product_name',
        key: 'id',
        ...this.getColumnSearchProps('product_name'),
        render: (text,record) =><div>
        
        <h6 onClick={()=>{

          console.log(record)
          this.setState({currenteditablecolumn:record})
          this.setState({showeditableform:true})

          this.setState({product_name:record.product_name})
          this.setState({sellingprice:record.unit_selling_price})
          this.setState({buyingprice:record.unit_buying_price})
          this.setState({discount_percentage:record.discount_percentage})
          this.setState({product_online:record.product_online})
          this.setState({description:record.description})
          
          this.setState({unit:record.unit})
          this.setState({photofile:record.product_pic})
          this.setState({product_category:record.product_category})



          }}>{record.product_name}</h6>

        </div>

      },

      {
        title: 'Primary Unit',
        dataIndex: 'unitname',
        key: 'id',
      },
      {
        title: 'Unit Buying Price',
        dataIndex: 'unit_buying_price',
        key: 'id',
      },
      {
        title: 'Unit Selling Price',
        dataIndex: 'unit_selling_price',
        key: 'id',
      },

      {
        title: 'Discount (%)',
        dataIndex: 'discount_percentage',
        key: 'id',
      },

      {
        title: 'Product online?',
        dataIndex: 'product_online',
        key: 'id',
        render: (text,record) =>
        <p style={{color:record.product_online?"green":"orange"}}>
          {record.product_online?"Yes":"No"}
        </p>
      },

      {
        title: 'Action',
        dataIndex: 'id',
        key: 'id',
        render: (text,record) =>
        <p>
        <Popover content={<p>Click here to edit,view details</p>} title="Edit,View">
         <FundViewOutlined style={{color:'blue'}}
         
         onClick={()=>{

          console.log(record)
          this.setState({currenteditablecolumn:record})
          this.setState({showeditableform:true})

          this.setState({product_name:record.product_name})
          this.setState({sellingprice:record.unit_selling_price})
          this.setState({buyingprice:record.unit_buying_price})
          this.setState({discount_percentage:record.discount_percentage})
          this.setState({product_online:record.product_online})
          this.setState({description:record.description})
          this.setState({photofile:record.product_pic})
          
          this.setState({unit:record.unit})
          this.setState({product_category:record.product_category})

          }}
         
         
         
         />
        </Popover>
 
        &nbsp;&nbsp;&nbsp;
        <Popover content={<p>Click here to delete product</p>} title="Delete">
        <Popconfirm title="Sure to delete?" onConfirm={() => this.handleDelete(text)}>
          <DeleteOutlined style={{color:'red'}}/>
         </Popconfirm>
        </Popover>
        </p>,
      }

    ];

 

    return(
        <div>
            <Collapse defaultActiveKey={['1']} onChange={this.callback}>
            <Panel header="ALL PRODUCTS" key="1">
            <Table  
            scroll={{ x: 1000 }} 
            columns={columns} 
            pagination={{showQuickJumper:true,showSizeChanger:true }}
            dataSource={this.state.products} 
            bordered/>
            </Panel>
            </Collapse>
            <br></br>            
            <Collapse defaultActiveKey={['0']} onChange={callback}>
            <Panel header="Collapse Panel to open Form" key="1">
              <h2>Create a product here</h2>
              <ProductForm 
              requestType="post"
              productID={null}
              btnText="Create"
              /> 
            </Panel>
            </Collapse>

            <Modal
                  visible={this.state.showeditableform}
                  title="Product Edit Form"
                  width={600}
                  onCancel={()=>{
                      this.setState({showeditableform:false})
                  }}

                  footer={[
                    <Button key="back" onClick={()=>{
                        this.setState({showeditableform:false})
                    }}>
                      Cancel
                    </Button>
        
                    ]}
                    >
                 <Form
                  onFinish={(event) => {

                  }}>

                  <FormItem label="Name">
                  <Input placeholder="Product Name"  value={this.state.product_name} onChange={(val)=>{this.setState({product_name:val.target.value})}} />
                  </FormItem>
              
                  <FormItem label="Unit">
                    <Select placeholder="Unit" style={{ width: 120 }} value={this.state.unit} onChange={(val)=>{this.setState({unit:val})}} >
                      {this.state.productunits.map(
                        (unit)=>(
                          <Option value={unit.id}>{unit.unitname}</Option>
                        ))}
                    </Select>
                  </FormItem>


                  <FormItem label="Product Category">
                    <Select placeholder="Product Category" style={{ width: 120 }}
                     value={this.state.product_category} 
                     onChange={(val)=>{this.setState({product_category:val})}} >
                      {this.state.productcategories.map(
                        (ctgy)=>(
                          <Option value={ctgy.id}>{ctgy.name}</Option>
                        ))}
                    </Select>
                  </FormItem>



                  <FormItem label="Buying Price">
                  <Input placeholder="Buying Price"  value={this.state.buyingprice} onChange={(val)=>{this.setState({buyingprice:val.target.value})}} />
                  </FormItem>

                  <FormItem label="Selling Price">
                  <Input placeholder="Selling Price"  value={this.state.sellingprice} onChange={(val)=>{this.setState({sellingprice:val.target.value})}} />
                  </FormItem>

                  <FormItem label="Discount percentage" >
                  <Input name="discount_percentage" type="number"  placeholder="Discount" value={this.state.discount_percentage} onChange={(val)=>{this.setState({discount_percentage:val.target.value})}}/>
                  </FormItem>

  
                  <FormItem label="Description">
                  <TextArea
                    placeholder="Description here"
                    autoSize={{ minRows: 2, maxRows: 6 }}
                    value={this.state.description} 
                    onChange={(val)=>{this.setState({description:val.target.value})}} 
                  />

                </FormItem> 


                  <Form.Item
                  label="Is product online?" >
                <Switch
                    checked={this.state.product_online}
                    onChange={val=>{this.setState({product_online:val})}}
                    checkedChildren="True"
                    unCheckedChildren="False"
                    />
                </Form.Item>

                <div style={{display:'flex',flexDirection:'row'}}>
                  <FormItem label="Product Image" style={{marginRight:3}}>
                        <Input type="file" accept="image/jpeg" placeholder="Product Image"  onChange={this.handlePhotoChange}/>
                  </FormItem> 
      
                  {this.state.photofile!=null ?
                  <Image
                  width={50}
                  height={50}
                  src={this.state.photofile}    
                  />
                  :
                  null
                  }
      
                  </div>

                

                  <FormItem>
                  <Button  type='primary' htmlType='button'
                  onClick={()=>{
                     
                    let form_data = new FormData();

                    form_data.append('product_name', this.state.product_name);
                    form_data.append('unit', this.state.unit);
                    form_data.append('unit_selling_price', this.state.sellingprice);
                    form_data.append('unit_buying_price', this.state.buyingprice);
                    form_data.append('discount_percentage', this.state.discount_percentage);
                    form_data.append('product_online', this.state.product_online);

                    form_data.append('description', this.state.description);
                    form_data.append('product_category', this.state.product_category);

                    this.state.Photo==null?
                    console.log("No profile file")
                    :
                    form_data.append('product_pic', this.state.Photo, this.state.Photo?this.state.Photo.name:"");
              
                    axios.put(serverconfig.backendserverurl+`/api/products/${this.state.currenteditablecolumn.id}/`, form_data, {
                    headers: {
                        'content-type': 'multipart/form-data'
                    }
                    })
                    .then(res => {
                      axios.get(serverconfig.backendserverurl+`/api/products/`)
                      .then(res => {
                          this.setState({
                              products:res.data
                          })
                      })
                  
                      axios.get(serverconfig.backendserverurl+`/api/productunits/`)
                      .then(res => {
                          this.setState({
                            productunits:res.data
                          })

                          this.setState({showeditableform:false})

                      })



                      axios.get(serverconfig.backendserverurl+`/api/productcategories/`)
                      .then(res => {
                          this.setState({
                            productcategories:res.data
                          })

                          this.setState({showeditableform:false})

                      })

                    }
                    )
                    .catch(error => console.log(error))

                  }}
                  
                  >Update</Button>
                  </FormItem>
                 </Form>
            
             </Modal>

        </div>
    )
  }
}

export default ProductList; 
