import React from 'react'
import { connect } from 'react-redux'
import { Form, Input, Button,Spin,Card,Avatar,Anchor,BackTop,Affix,Image, message } from 'antd';
import { UserOutlined, LockOutlined, LoadingOutlined, PhoneFilled, PhoneOutlined, MessageOutlined, MailOutlined, BankOutlined } from '@ant-design/icons';
import { NavLink } from 'react-router-dom'
import { withRouter } from 'react-router-dom';
import axios from 'axios'
import { Layout, Menu, Breadcrumb,Carousel,Tabs,Collapse } from 'antd';
import {
  Col,
  Row,
} from 'reactstrap';
import * as serverconfig from './serverconn'

const { Header, Content, Footer } = Layout;

const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

const { TabPane } = Tabs;
const { Panel } = Collapse;
const { Meta } = Card;
const FormItem=Form.Item;

const contentStyle = {
  //height: '200px',
  color: '#fff',
  lineHeight: '200px',
  //textAlign: 'center',
  background: '#BEBBB9',
  padding:5,
  //borderRadius:30
};

const style = {
  height: 40,
  width: 40,
  lineHeight: '40px',
  borderRadius: 4,
  backgroundColor: '#FD7B15',
  color: '#fff',
  textAlign: 'center',
  fontSize: 14,
};

class RequestDeactivation extends React.Component {

  state = {
    reason_ofdeaction:'',
    phonecontact:''

  }


  render(){

      return (
        <div>
          {
            this.state.datarequested===true?
            <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
            <Spin size="large">
            <div className="content">
              <h3>Umoja couriers</h3>
            </div>
             </Spin> 
             
              </div>
    
            :
            <Card style={{borderRadius:6}}>
              <h3>Account deactivation request page</h3>

            <FormItem label="Account Phone contact"    
              >
                <Input   name="Account Phone"  placeholder="Phone" value={this.state.phonecontact} onChange={(val)=>{this.setState({phonecontact:val.target.value})}} />
              </FormItem>
  
  
              <FormItem label="Reason for deactivation"    
              >
                <Input   name="Reason"  placeholder="Reason" value={this.state.reason_ofdeaction} onChange={(val)=>{this.setState({reason_ofdeaction:val.target.value})}} />
              </FormItem>
  
              <FormItem>
              <Button  type="primary" htmlType="button"
               onClick={()=>{
  
  
            let form_data = new FormData();
            form_data.append('reason_ofdeaction', this.state.reason_ofdeaction);
            form_data.append('phonecontact', this.state.phonecontact);

            if(this.state.phonecontact===''){
              message.error("Account contact missing")
            } else{
              this.setState({datarequested:true})

              //Now submit sale data to database
              axios.post(serverconfig.backendserverurl+'/customqueries/requestaccount_deactivation', form_data,{
              headers: {
                'content-type': 'multipart/form-data'
              }
              })
              .then(res =>{
                this.setState({datarequested:false})
                  message.info(res.data.message)  
              } 
              )
              .catch(error => console.log(error))   
            }
    
              }}>Submit</Button>
            </FormItem>
              </Card>
          }
          
        </div>
        );
  }
}



export default RequestDeactivation;
