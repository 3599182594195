import React from 'react';
import HubForm from '../components/HubForm'
import axios from 'axios'
import { Table, Input, Button,Collapse,Popover,DatePicker,Popconfirm,Select,message,Spin, Tabs,Form } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon from '@ant-design/icons';
import { SearchOutlined,DeleteOutlined,FundViewOutlined,LoadingOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import * as serverconfig from '../../serverconn'
import * as serversocketconfig from '../../serversocketconfig'
import ReactToPrint from 'react-to-print';
import * as reactstrp from 'reactstrap';
import * as primarycolor from '../../primarycolor'

import ReconnectingWebSocket from 'reconnecting-websocket';
import {
  withGoogleMap,
  withScriptjs,
  GoogleMap,
  DirectionsRenderer,
  Marker
} from "react-google-maps";

import SportsMotorsportsIcon from '@mui/icons-material/SportsMotorsports';

import PolylineMap_Activities from '../components/PolylineMap_Activities';


var CryptoJS = require("crypto-js");

const { Panel } = Collapse;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const { TabPane } = Tabs;
const FormItem=Form.Item;
const { Option } = Select;
const dateFormat = 'DD/MM/YYYY';


function callback(key) {
  console.log(key);
}

var token= ''
const { RangePicker } = DatePicker;


class Hub_AgentActivityMap extends React.Component {
  state = {
    searchText: '',
    searchedColumn: '',
    datarequested:true,
    day_activities:[],

    selected_acccount:'',
    date:'',
    accounts:[]

  };

  componentDidMount(){
  
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    //get agents
    axios.get(serverconfig.backendserverurl+`/customqueries/get_hubaccounts/${localStorage.getItem("coodinated_hub")}`)
    .then(res => {
        this.setState({
          accounts:JSON.parse(res.data.report) 
        })
        this.setState({datarequested:false})
    })

}

  render() {
    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin size="large">
        <div className="content">
          <h3>Umoja couriers</h3>
        </div>
         </Spin> 
         
          </div>
      )

    }else{
      return(
        <div>
              <Form layout="inline" >
                 <FormItem label="Agent">
                     <Select 
                     placeholder="Agent"
                     style={{ width: 200 }} 
                     value={this.state.selected_acccount} 
                     onChange={(val)=>{this.setState({selected_acccount:val})}}
                      
                     showSearch
                     optionFilterProp="children"
                     onFocus={this.onFocus}
                     onBlur={this.onBlur}
                     onSearch={this.onSearch}
                     filterOption={(input, option) =>
                       option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                     }                     
                      >
                       {this.state.accounts.map(
                      (item)=>(
                        <Option value={item.id}>{item.username} </Option>
                      ))}

                     </Select>
                 </FormItem>

                 <FormItem label="Date">
                      <DatePicker onChange={(date, dateString) =>{
                        this.setState({ date: dateString});
                      }} format={dateFormat} />
                  </FormItem>

                  <FormItem>
                  <Button 

                  onClick={()=>{

                      let form_data = new FormData();
                      form_data.append('date', this.state.date);
                      form_data.append('selected_acccount', this.state.selected_acccount);

                     if(this.state.date===''){
                        message.error("Date missing")
                      }else if(this.state.selected_acccount===''){
                      message.error("Agent account missing")
                     } else{
                        this.setState({datarequested:true})
                        this.setState({day_activities:[]})
                
                        //Now submit sale data to database
                        axios.post(serverconfig.backendserverurl+'/customqueries/getagent_dayactivities', form_data,{
                        headers: {
                          'content-type': 'multipart/form-data'
                        }
                        })
                        .then(res =>{
                          this.setState({datarequested:false})
                          this.setState({day_activities:JSON.parse(res.data.report)})

                        } 
                        )
                        .catch(error => console.log(error))   
                    }

                  }} type="primary" htmlType="button">Search</Button>
                  </FormItem> 
  
             </Form>

          {
            this.state.day_activities.length>0?
            <PolylineMap_Activities initialCoordinates={this.state.day_activities} />
            :
            null
          }
 
        </div>
    )

    }

   
  }
}

export default Hub_AgentActivityMap; 
