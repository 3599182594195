import React from 'react';
import { Form, Input, Button,Spin,Select,Modal,Card } from 'antd';
import axios from 'axios'
import * as serverconfig from '../../serverconn'
import { UserOutlined, LockOutlined, LoadingOutlined, MailOutlined,UploadOutlined,PrinterOutlined } from '@ant-design/icons';
import * as reactstrp from 'reactstrap';
import BarCodeView from '../containers/BarCodeView'

import ReactToPrint from 'react-to-print';
import Barcode from 'react-barcode';
import {
  Col,
  Row,
} from 'reactstrap';


var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const { Option } = Select;

var token= ''
var bizuserid=''

class ReportToPrint extends React.Component {

  state = {
    companyprofile:{},
  };

  componentDidMount(){

    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

  }

  render() {
    return (
      <div style={{
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        alignSelf:'center',
        padding:5
      }}>

<style>
        {`@media print {
  body::after {
    content: "Umoja intergrated medical couriers";
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 48px;
    opacity: 0.3;
  }
}`}
          </style>
<p></p>
<p></p>
<p></p>
<p></p>

           {
            this.props.sample_barcodes.length>0?
             <Row>
              {
                this.props.sample_barcodes.map((item)=>{
                  return (
                  <Col xs="2" sm="2" lg="2" style={{padding: '5px'}}>
                    <Barcode value={item.bar_code}  width={1} height={50} displayValue={true}/>
                 </Col>
                  )
                })
              }
              </Row>
            :
            null
           }

      </div>
    );
  }
}



class SampleBarCodeForm extends React.Component {

  state = {
    numer_of_codes:'' ,  
    bar_code_type:'',
    datarequested:false,
    sample_barcodes:[],

    show_qrModel:false,
    selected_record:{},
    active_sample_barcodes:[],
    userrights:{},


  }

  componentDidMount(){
  

    if(localStorage.getItem("bizuserid")){
      bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
   }else{
      bizuserid= ''
   }
    
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }


    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }


    axios.get(serverconfig.backendserverurl+`/api/sample_barcodes/`)
    .then(res => {
        this.setState({
          sample_barcodes:res.data
        })

    })


    axios.get(serverconfig.backendserverurl+`/api/sample_barcodes/?status=${'active'}`)
    .then(res => {
        this.setState({
          active_sample_barcodes:res.data
        })

    })


    axios.get(serverconfig.backendserverurl+`/api/accountrights/?user=${bizuserid}`)
    .then(res => {
       console.log("data: "+res.data[0] )
        this.setState({
          userrights:res.data[0]
        })
    })

  }


  //submit button pressed
  handleFormSubmit=(event) =>{
    this.setState({datarequested:true})

    let form_data = new FormData();
    form_data.append('numer_of_codes', this.state.numer_of_codes);
    form_data.append('bar_code_type', this.state.bar_code_type);

      axios.post(serverconfig.backendserverurl+'/customqueries/generate_barcodes', form_data, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      })
    .then(res => this.setState({datarequested:false})
,    window.location.reload(false)
    )
    .catch(error => console.log(error))

  }


  render(){

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{
      return (
        <div>

          <div style={{display: "none"}}>
          &nbsp;&nbsp;
          <ReportToPrint
          title={"Barcodes"} 
          sample_barcodes={this.state.active_sample_barcodes}
          ref={el => (this.componentRef = el)} /> 
          </div>

          <ReactToPrint
            trigger={() => 
            <Button type="primary"  shape="round" icon={<PrinterOutlined />} size={this.state.size}>
            Print active codes
            </Button>
            }
            content={() => this.componentRef}
            />


      <h3>Barcodes</h3>

        <reactstrp.Table bordered>
       <thead>
        <tr>
          <th>Date</th>
          <th>Barcode type</th>
          <th>Code</th>
          <th>Status</th>
          <th>View</th>
        </tr>
      </thead>
      <tbody>
      {this.state.sample_barcodes.map(
        (itm)=>(
          <tr>
            <td>{itm.date}</td>
            <td>{itm.bar_code_type}</td>
            <td>{itm.bar_code}</td>
            <th style={{color:itm.status==="used"?'green':"orange"}}>{itm.status}</th>
            <td><Button onClick={()=>{

              this.setState({show_qrModel:true})
              this.setState({selected_record:itm})
            }}>View code</Button></td>
          </tr>
        ))}
        
    </tbody>
    </reactstrp.Table>


    <h3>Generate new codes</h3>
     {
     this.state.userrights.create_barcodes===true?
    <Form
        onFinish={(event) => this.handleFormSubmit(event)}
    >

      <FormItem label="Barcode type"
          name="bar_code_type"
          rules={[
            {
              required: true,
              message: 'Please select',
            },
          ]}  
      >
          <Select placeholder="Barcode type " 
          value={this.state.bar_code_type} 
          onChange={(val)=>{this.setState({bar_code_type:val})}} 
          showSearch
          optionFilterProp="children"
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          onSearch={this.onSearch}
          >
          <Option value="single_entry">Single entry</Option>
          <Option value="batch_entry">Batch entry</Option>
          </Select>
      </FormItem>


      <FormItem label="No of codes"
        name="numer_of_codes"
        rules={[
          {
            required: true,
            message: 'Please input number of codes',
          },
        ]}
      >
        <Input name="numer_of_codes"
        placeholder="Put number here." 
        value={this.state.numer_of_codes} 
        onChange={(e) => this.setState({ numer_of_codes: e.target.value})} 
        type='number'
        />
      </FormItem>


      <FormItem>
        <Button  type="primary" htmlType="submit">Generate codes</Button>
      </FormItem>

      </Form>
      :
      null}

      {
          this.state.show_qrModel?
          <Modal              
          visible={this.state.show_qrModel}
          title="Barcode display"
          width={1000}
          onCancel={()=>{this.setState({show_qrModel:false})}}

          footer={[
            <Button key="back" onClick={()=>{this.setState({show_qrModel:false})}}>
              Cancel
            </Button>
            ]}
            >
              <BarCodeView 
                selected_record={this.state.selected_record}
                title={"SAMPLE BARCODE "}
                />
        
          </Modal>
          :
          null
        }


        </div>
      );

    }
    
  }

}


export default SampleBarCodeForm;



