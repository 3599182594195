import React from 'react';
import { Form, Input,message,Button,Select } from 'antd';
import axios from 'axios'
import * as serverconfig from '../../serverconn'

const FormItem=Form.Item;
const { Option } = Select;


class ProductCategoryForm extends React.Component {

  state = {
    name:'' ,  
    description:''
  }


  //submit button pressed
  handleFormSubmit=(event) =>{

    let form_data = new FormData();
    form_data.append('name', this.state.name);
    form_data.append('description', this.state.description);

    axios.post(serverconfig.backendserverurl+'/api/productcategories/', form_data, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    })
    .then(res => window.location.reload(false),
    message.info('Success fully created ')
    )
    .catch(error => console.log(error))


  }

  handlenameChange= (e) => this.setState({ name: e.target.value});
  handledescriptionChange= (e) => this.setState({ description: e.target.value});


  render(){
    return (
      <div>
        <Form onFinish={(event) => this.handleFormSubmit(event)}>
          <FormItem label=" Name"
          name="name"
          rules={[
            {
              required: true,
              message: 'Please input name',
            },
          ]}          
          >
            <Input  placeholder="Put a name here." value={this.state.name} onChange={this.handlenameChange} />
          </FormItem>

          <FormItem label="Description"
          name="unitdescription"
          rules={[
            {
              required: true,
              message: 'Please input description',
            },
          ]}          
          >
            <Input  placeholder="Details." value={this.state.description} onChange={this.handledescriptionChange} />
          </FormItem>

          <FormItem>
            <Button  type="primary" htmlType="submit">Create</Button>
          </FormItem>
        </Form>
      </div>
    );
  }

}


export default ProductCategoryForm;



