import React from 'react';
import { Route } from 'react-router-dom';
import QuickMenu from './views/MainBranch/containers/QuickMenu'
import DashBoard from './views/MainBranch/containers/DashBoard'
import AgentList from './views/MainBranch/containers/AgentsListView'
import Hub_AgentsList from './views/MainBranch/containers/Hub_AgentsListView'

import DeliveryAgentDetails from './views/MainBranch/containers/DeliveryAgentDetails'

import DistrictList from './views/MainBranch/containers/UgDistrictListView'
import UgDistrictDetail from './views/MainBranch/containers/UgDistrictDetailView'
import AccountList from './views/MainBranch/containers/AccountsListView'
import AccountDetail from './views/MainBranch/containers/AccountDetailView'

import SupplyList from './views/MainBranch/containers/SupplyListView'
import SupplyDetail from './views/MainBranch/containers/SupplyDetailView'

import SampleList from './views/MainBranch/containers/SampleListView'
import SampleDetail from './views/MainBranch/containers/SampleDetailView'

import HubsList from './views/MainBranch/containers/HubsListView'
import HubDetails from './views/MainBranch/containers/HubDetails'

import HealthCenterList from './views/MainBranch/containers/HealthCenterListView'
import Hub_HealthCenterList from './views/MainBranch/containers/Hub_HealthCenterListView'


import HealthCenterDetails from './views/MainBranch/containers/HealthCenterDetails'

import OnlineAgentsList from './views/MainBranch/containers/OnlineAgentsListView'
import ProductList from './views/MainBranch/containers/ProductListView'
import UnitList from './views/MainBranch/containers/ProductUnitListView'
import SupplierList from './views/MainBranch/containers/SupplierListView'
import SupplierDetail from './views/MainBranch/containers/SupplierDetailView'

import OrdersList from './views/MainBranch/containers/OrdersListView'
import CompanyProfile from './views/MainBranch/containers/companyProfile'
import ProductCategoriesList from './views/MainBranch/containers/ProductCategoriesListView'
import ClientList from './views/MainBranch/containers/ClientListView';


const SaccoBaseRouter = () =>(

     <div>
        <Route exact path='/' component={DashBoard} />
        <Route exact path='/quickmenu/' component={QuickMenu} />
        <Route exact path='/deliveryagents/' component={AgentList} />
        <Route exact path='/hub_deliveryagents/' component={Hub_AgentsList} />

        <Route exact path='/deliveryagents/:agentID' component={DeliveryAgentDetails} />

        <Route exact path='/ugdistricts/' component={DistrictList}  />
        <Route exact path='/ugdistricts/:districtID' component={UgDistrictDetail}  />
        <Route exact path='/useraccounts/' component={AccountList} />
        <Route exact path='/useraccounts/:accountID' component={AccountDetail} />

        <Route exact path='/supplies/' component={SupplyList} />
        <Route exact path='/supplies/:supplyID' component={SupplyDetail} />

        <Route exact path='/samples/' component={SampleList} />
        <Route exact path='/samples/:sampleID' component={SampleDetail} />

        <Route exact path='/collectionhubs/' component={HubsList} />
        <Route exact path='/collectionhubs/:hubID' component={HubDetails} />

        <Route exact path='/healthcenters/' component={HealthCenterList} />
        <Route exact path='/hub_healthcenters/' component={Hub_HealthCenterList} />

        <Route exact path='/healthcenters/:centerID' component={HealthCenterDetails} />
        <Route exact path='/online_agents/' component={OnlineAgentsList} />

        <Route exact path='/products/' component={ProductList} />
        <Route exact path='/product_units/' component={UnitList} />
        <Route exact path='/product_categories/' component={ProductCategoriesList} />

        <Route exact path='/suppliers/' component={SupplierList} />
        <Route exact path='/supplierdetails/:supplierID' component={SupplierDetail} />

        <Route exact path='/orders/' component={OrdersList} />
        <Route exact path='/companyprofile/' component={CompanyProfile} />
        <Route exact path='/app_clients/' component={ClientList} />


    </div>
);

export default SaccoBaseRouter;


