import React from 'react';
import { Form, Input, Button,Select,Checkbox,Upload,Spin,Switch,DatePicker } from 'antd';
import axios from 'axios'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import { UserOutlined, LockOutlined, LoadingOutlined, MailOutlined,UploadOutlined } from '@ant-design/icons';
import * as serverconfig from '../../serverconn'
import Resizer from "react-image-file-resizer";

const FormItem=Form.Item;
const { Option } = Select;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const dateFormat = 'DD/MM/YYYY';

var token= ''

class AccountUpdateForm extends React.Component {
  formRef = React.createRef();

  state = {
    profile_pic:null,   
    profileuploadname:"",
    datarequested:false,
    userprofile:{},

    is_active:false,
    profile_pic:null,  
    selected_role:'',
    username:'',
    email:'',
    managerial_account:false,

    sur_name:'',
    other_name:'',
    nationality:'',
    date_Of_Birth:'',
    Area_Of_Residence:'',
    gender:'',
    coodinated_hub:'',
    collectionhubs:[]

  }


  componentDidMount(){
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

   

    //this.setState({datarequested:false})
    axios.get(`${serverconfig.backendserverurl}/api/accounts/${this.props.accountID}`)
    .then(res => {  
        this.setState({
          userprofile: res.data
        })
        this.setState({datarequested:false})

        this.setState({username:res.data.username})
        this.setState({email:res.data.email})
        this.setState({managerial_account:res.data.branch_manager})

        this.setState({is_active:res.data.is_active})
        this.setState({coodinated_hub:res.data.coodinated_hub})

    })


    axios.get(serverconfig.backendserverurl+`/api/collectionhubs/`)
    .then(res => {
        this.setState({
          collectionhubs:res.data
        })
        this.setState({datarequested:false})

    })


  }




  //submit button pressed
  onFinish=values =>{
    this.setState({datarequested:true})

    let form_data = new FormData();
    form_data.append('username', this.state.username);
    form_data.append('email', this.state.email);
    form_data.append('is_active',this.state.is_active);
    form_data.append('branch_manager', this.state.managerial_account);
    form_data.append('is_admin', this.state.userprofile.is_admin);
    form_data.append('is_staff',this.state.userprofile.is_staff);
    
    form_data.append('is_superuser', this.state.userprofile.is_superuser);

    this.state.profile_pic==null?
    console.log("No profile file")
    :
    form_data.append('profile_pic', this.state.profile_pic, this.state.profile_pic?this.state.profile_pic.name:"");

    if(this.state.coodinated_hub!='' || this.state.coodinated_hub!='null'){
      form_data.append('coodinated_hub', this.state.coodinated_hub);
    }

     
    //Now send the post resquest
      axios.put(`${serverconfig.backendserverurl}/api/accounts/${this.props.accountID}/`, form_data, {
      headers: {
        'content-type': 'multipart/form-data'
      }
      })
    .then(res =>this.setState({datarequested:false}),
      window.location.reload(false)
     )
    .catch(error => console.log(error)) 

    };

     normFile = e => {
      console.log('Upload event:', e);
    
      if (Array.isArray(e)) {
        return e;
      }
    
      return e && e.fileList;
    };


  handleprofile_picChange=   async (e) =>{

    const file = e.target.files[0];
    //send to resizer
    const image = await this.resizeFile(file);
    const newFile = this.dataURIToBlob(image);
    this.setState({ profile_pic: newFile});

     //this.setState({ profile_pic: e.target.files[0]});
  } 



//imgae resizer
resizeFile = (file) =>
new Promise((resolve) => {
  Resizer.imageFileResizer(
    file,
    300,
    400,
    "JPEG",
    50,
    0,
    (uri) => {
      resolve(uri);
    },
    "base64"
  );
});

//convert back to file
 dataURIToBlob = (dataURI) => {
  const splitDataURI = dataURI.split(",");
  const byteString =
    splitDataURI[0].indexOf("base64") >= 0
      ? atob(splitDataURI[1])
      : decodeURI(splitDataURI[1]);
  const mimeString = splitDataURI[0].split(":")[1].split(";")[0];
  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);
  return new Blob([ia], { type: mimeString });
};


  render(){
    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
              <Spin  size="large">
                <div className="content" />
              </Spin> 
        </div>
      )

    }else{

      return (
        <div>
          <Form  
          onFinish={this.onFinish}        
          //ref={this.formRef}
          >

  
            <FormItem 
            label="User Name"
        
            >
              <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Put a name here." value={this.state.username} />
            </FormItem>
  
            <FormItem 
            label="Email"
           
            >
              <Input  type="mail" prefix={<MailOutlined className="site-form-item-icon" />}  placeholder="Put a name here." value={this.state.email}  />
            </FormItem>
  
            <Form.Item label="Profile Image"
            >
                <Input  type="file" accept="image/png, image/jpeg" placeholder="Profile Image" onChange={(e) => this.setState({ profile_pic: e.target.files[0]})}/>
            </Form.Item>  

            <Form.Item
              label="Is Active"

            >
                <Switch
                    checked={this.state.is_active}
                    onChange={val=>{this.setState({is_active:val})}}
                    checkedChildren="True"
                    unCheckedChildren="False"
                    />
              </Form.Item>
  
              <Form.Item
              label="Is Manager">
                <Switch
                    checked={this.state.managerial_account}
                    onChange={val=>{this.setState({managerial_account:val})}}
                    checkedChildren="True"
                    unCheckedChildren="False"
                    />            
             </Form.Item>
  
             <FormItem label="Coodinated hub.">
                <Select placeholder="Coodinated hub." 
                value={this.state.coodinated_hub}
                 onChange={(val)=>{this.setState({coodinated_hub:val})}} 
                showSearch
                optionFilterProp="children"
                onFocus={this.onFocus}
                onBlur={this.onBlur}
                onSearch={this.onSearch}    
                >
                  {this.state.collectionhubs.map(
                    (hb)=>(
                      <Option value={hb.id}>{hb.name}</Option>
                    ))}
                </Select>
            </FormItem>
  

            <FormItem>
              <Button htmlType="submit" type="primary">Update</Button>
            </FormItem>

          </Form>
  
        </div>
      );

    }
  }

}

export default AccountUpdateForm;



