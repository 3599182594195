import React from 'react';
import axios from 'axios';
import { Card, Button,Form,Input,message,Spin,} from 'antd';
import IDTypeForm from '../components/SupplyForm'
import { UserOutlined,LoadingOutlined,PrinterOutlined } from '@ant-design/icons';
import * as serverconfig from '../../serverconn'
import {
    Col,
    Row,
  } from 'reactstrap';
import QRCode from 'qrcode.react';
import ReactToPrint from 'react-to-print';
import Barcode from 'react-barcode';


const FormItem=Form.Item;
const { Meta } = Card;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

var token= ''

class ReportToPrint extends React.Component {

  state = {
    companyprofile:{},
  };

  componentDidMount(){

    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

  }

  render() {
    return (
      <div style={{
        display:'flex'
        ,justifyContent:'center',
        alignItems:'center',
        alignSelf:'center',
        padding:200
     
      }}>

<style>
        {`@media print {
  body::after {
    content: "Umoja intergrated medical couriers";
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 48px;
    opacity: 0.3;
  }
}`}
          </style>
<p></p>
<p></p>
<p></p>
<p></p>

        <Card>
            <h3 style={{fontWeight:'bolder'}}>{this.props.title}</h3>
           <br></br>
           <br></br>
           <Barcode value={this.props.details}  width={1} height={50} displayValue={true}/>
        </Card>

      </div>
    );
  }
}




class BarCodeView extends React.Component{

    state={
      details:''
    }

    componentDidMount(){
        if(localStorage.getItem("token")){
          token= localStorage.getItem("token")
        }else{
          token= ''
        }

        axios.defaults.headers={
            "Content-Type":"application/json",
            Authorization:`Token ${token}`
          }

          this.setState({details:this.props.selected_record.bar_code
          })

          this.setState({datarequested:false})
    }



    render(){

        if(this.state.datarequested===true){
            return(
              <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
              <Spin indicator={antIcon} />
              </div>
            )
      
          }else{
            return(
                <div style={{display:'flex',justifyContent:'center'}}>
                  <Card>
                  <div style={{display: "none"}}>
                    &nbsp;&nbsp;
                    <ReportToPrint
                    title={this.props.title} 
                    details={this.state.details}
                    ref={el => (this.componentRef = el)} /> 
                   </div>

                  <Barcode value={this.state.details}  width={1} height={50} displayValue={true}/>

                <br></br>
                <br></br>

                  <ReactToPrint
                  trigger={() => 
                  <Button type="primary"  shape="round" icon={<PrinterOutlined />} size={this.state.size}>
                  Print
                  </Button>
                  }
                  content={() => this.componentRef}
                  />
                  </Card>
                </div>
            )

          }
    }
}

export default BarCodeView; 