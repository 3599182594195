import React from 'react';
import SampleForm from '../components/SampleForm'
import axios from 'axios'
import { Table, Input, Button,Collapse,Popover,Popconfirm,message,Spin,Form,DatePicker,Select } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon from '@ant-design/icons';
import { SearchOutlined,DeleteOutlined,FundViewOutlined,DownloadOutlined,LoadingOutlined,PrinterOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import * as serverconfig from '../../serverconn'
import CurrencyFormat from 'react-currency-format';
import * as reactstrp from 'reactstrap';
import ReactToPrint from 'react-to-print';
import {
  Col,
  Row,
} from 'reactstrap';
import ReactExport from "@ibrahimrahmani/react-export-excel";
import moment from 'moment';


var CryptoJS = require("crypto-js");

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


const { Panel } = Collapse;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const FormItem=Form.Item;
var token= ''
const { Search } = Input;
const { Option } = Select;


const dateFormat = 'DD/MM/YYYY';
const { RangePicker } = DatePicker;


class ReportToPrint extends React.Component {

  state = {
    companyprofile:{},
  };

  componentDidMount(){

    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(serverconfig.backendserverurl+`/api/app_profile/${1}`)
    .then(res => {  
        this.setState({
          companyprofile: res.data
        })

    })



  }



  render() {
    return (
      <div style={{padding:20}}>
            <Row> 
              <Col xs="2" sm="2" lg="2">
                <img height="100" width="120"  alt="Logo" src={this.state.companyprofile.company_logo} />
                </Col>
                <Col xs="6" sm="6" lg="6">
                <h3 style={{fontWeight:'bolder'}}>{this.state.companyprofile.system_name}</h3>
                <h6 style={{fontWeight:'bolder'}}>Tel: {this.state.companyprofile.RegisteredPhoneNumber1}</h6>               
                <h5 style={{fontWeight:'bolder'}}>Email: {this.state.companyprofile.company_email}</h5>
              
                </Col>
            </Row>

            <h2 style={{display: 'flex',justifyContent:'center', alignItems:'center',fontWeight:'bolder'}}>UMOJA DELIVERY DETAILS</h2>
            <h4 style={{fontWeight:'bolder'}} align='left'>{' DEIVERY NUMBER: '+this.props.selected_record.delivery_number}</h4>
            <h4 style={{fontWeight:'bolder'}}align='left'>{' DATE: '+this.props.selected_record.date}</h4>
            <h4 style={{fontWeight:'bolder'}}align='left'>{' PICKUP POINT: '+this.props.selected_record.pickup_point}</h4>
            <h4 style={{fontWeight:'bolder'}}align='left'>{' DESTINATION: '+this.props.selected_record.destination}</h4>
            <h4 style={{fontWeight:'bolder'}}align='left'>{' TIME OF PICKUP: '+this.props.selected_record.time_of_pickup}</h4>
            <h4 style={{fontWeight:'bolder'}}align='left'>{' TIME OF DELIVERY: '+this.props.selected_record.time_of_dropoff}</h4>
            <h4 style={{fontWeight:'bolder'}}align='left'>{' STATUS: '+this.props.selected_record.delivery_status}</h4>

               <reactstrp.Table bordered>
                    <thead>
                    <tr>
                    <th>No</th>
                    <th>Item type</th>
                    <th>Item</th>
                    <th>Quantity</th>
                    <th>Description</th>
                    <th>Barcode</th>

                    </tr>
                    </thead>
                    <tbody>
                    { JSON.parse(this.props.selected_record.delivery_items).map(
                      (item)=>(
                        <tr>
                         <td>{item.key}</td>
                         <td>{item.item_type}</td>
                        <td>{item.item_name}</td>
                        <td>{item.quantity}</td>
                        <td>{item.description}</td>
                        <td>{item.barcode}</td>

                        </tr>
                      ))}

                  </tbody>
              </reactstrp.Table> 
      </div>
    );
  }
}


class ReportToPrint2 extends React.Component {

  state = {
    companyprofile:{},
  };

  componentDidMount(){

    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(serverconfig.backendserverurl+`/api/app_profile/${1}`)
    .then(res => {  
        this.setState({
          companyprofile: res.data
        })

    })



  }



  render() {
    return (
      <div style={{padding:20}}>
            <Row> 
              <Col xs="2" sm="2" lg="2">
                <img height="100" width="120"  alt="Logo" src={this.state.companyprofile.company_logo} />
                </Col>
                <Col xs="6" sm="6" lg="6">
                <h3 style={{fontWeight:'bolder'}}>{this.state.companyprofile.system_name}</h3>
                <h6 style={{fontWeight:'bolder'}}>Tel: {this.state.companyprofile.RegisteredPhoneNumber1}</h6>               
                <h5 style={{fontWeight:'bolder'}}>Email: {this.state.companyprofile.company_email}</h5>
              
                </Col>
            </Row>

            <h2 style={{display: 'flex',justifyContent:'center', alignItems:'center',fontWeight:'bolder'}}>UMOJA DELIVERIES FROM {this.props.dateone} TO {this.props.datetwo}</h2>

               <reactstrp.Table bordered>
                    <thead>
                    <tr>
                    <th>Delivery No</th>
                    <th>Date</th>
                    <th>Delivery status</th>
                    <th>Agent</th>
                    <th>Mode</th>

                    <th>Pickup</th>
                    <th>Destination</th>
                    <th>Pickup time</th>
                    <th>Delivery time</th>
                    <th>Items total</th>

                    </tr>
                    </thead>
                    <tbody>
                    { this.props.pending_deliveries.map(
                      (item)=>(
                        <tr>
                         <td>{item.delivery_number}</td>
                         <td>{item.date}</td>
                         <td>{item.delivery_status}</td>
                         <td>{item.agent_account_name}</td>
                         <td>{item.delivery_mode}</td>
                         <td>{item.pickup_point}</td>
                         <td>{item.destination}</td>
                         <td>{item.time_of_pickup}</td>
                         <td>{item.time_of_dropoff}</td>
                         <td>{item.items_total}</td>

                        </tr>
                      ))}

                  </tbody>
              </reactstrp.Table> 
      </div>
    );
  }
}



class Hub_DeliveriesList extends React.Component {
  state = {
    searchText: '',
    searchedColumn: '',
    pending_deliveries: [],
    pending_deliveries_placeholders: [],

    datarequested:true,
    selected_record:{},
    dateone:moment().format(dateFormat).toString(),
    datetwo:moment().format(dateFormat).toString(),
    delivery_status:'All'

  };

  componentDidMount(){
 
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    /*
    axios.get(serverconfig.backendserverurl+`/api/pending_deliveries`)
    .then(res => {

        this.setState({
            pending_deliveries:res.data
        })
        this.setState({
          pending_deliveries_placeholders:res.data
      })
        
        this.setState({datarequested:false})

    })
    */

    //get todays data

    let form_data = new FormData();
    form_data.append('dateone', this.state.dateone);
    form_data.append('datetwo', this.state.datetwo);
    form_data.append('delivery_status', this.state.delivery_status);
    form_data.append('hub', localStorage.getItem("coodinated_hub"));

   if(this.state.dateone===''||this.state.datetwo===''){
      message.error("Please dates are missing")
    }else{
      this.setState({datarequested:true})
      this.setState({pending_deliveries:[]})
      this.setState({pending_deliveries_placeholders:[]})

      //Now submit sale data to database
      axios.post(serverconfig.backendserverurl+'/customqueries/getumoja_deliveriesreport', form_data,{
      headers: {
        'content-type': 'multipart/form-data'
      }
      })
      .then(res =>{
        this.setState({datarequested:false})
        this.setState({pending_deliveries:JSON.parse(res.data.report)})
        this.setState({pending_deliveries_placeholders:JSON.parse(res.data.report)})

      } 
      )
      .catch(error => console.log(error)) 
    } 



}

getColumnSearchProps = dataIndex => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    <div style={{ padding: 8 }}>
      <Input
        ref={node => {
          this.searchInput = node;
        }}
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
      <Button
        type="primary"
        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        icon={<SearchOutlined />}
        size="small"
        style={{ width: 90, marginRight: 8 }}
      >
        Search
      </Button>
      <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
        Reset
      </Button>
    </div>
  ),
  filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilter: (value, record) =>
    record[dataIndex]
      .toString()
      .toLowerCase()
      .includes(value.toLowerCase()),
  onFilterDropdownVisibleChange: visible => {
    if (visible) {
      setTimeout(() => this.searchInput.select());
    }
  },
  render: text =>
    this.state.searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ) : (
      text
    ),
});

handleSearch = (selectedKeys, confirm, dataIndex) => {
  confirm();
  this.setState({
    searchText: selectedKeys[0],
    searchedColumn: dataIndex,
  });
};

handleReset = clearFilters => {
  clearFilters();
  this.setState({ searchText: '' });
};

 

  render() {
    const columns = [
      {
        title: 'Delivery no',
        dataIndex: 'delivery_number',
        key: 'id',
        ...this.getColumnSearchProps('delivery_number'),
      },
      {
        title: 'date',
        dataIndex: 'date',
        key: 'id',
        ...this.getColumnSearchProps('date'),
      },

      {
        title: 'Delivery status',
        dataIndex: 'delivery_status',
        key: 'id',
        ...this.getColumnSearchProps('delivery_status'),
        render: (text,record) =>
        <p style={{color:record.delivery_status==="pending"?"orange":"green"}}>
           {text}
        </p>
      },

      {
        title: 'Agent ',
        dataIndex: 'agent_account_name',
        key: 'id',
        ...this.getColumnSearchProps('agent_account_name'),
      },
      {
        title: 'Delivery mode ',
        dataIndex: 'delivery_mode',
        key: 'id',
        ...this.getColumnSearchProps('delivery_mode'),
      },
      {
        title: 'Pickup point ',
        dataIndex: 'pickup_point',
        key: 'id',
        ...this.getColumnSearchProps('pickup_point'),
      },
      {
        title: 'Destination ',
        dataIndex: 'destination',
        key: 'id',
        ...this.getColumnSearchProps('destination'),
      },
      {
        title: 'Time of pickup ',
        dataIndex: 'time_of_pickup',
        key: 'id',
      },
      
      {
        title: 'Time of delivery ',
        dataIndex: 'time_of_dropoff',
        key: 'id',
      },
      {
        title: 'Items total ',
        dataIndex: 'items_total',
        key: 'id',
      },
    ];

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin size="large">
        <div className="content">
          <h3>Umoja couriers</h3>
        </div>
         </Spin> 
          </div>
      )

    }else{
      return(
        <div>

            <div style={{display: "none"}}>
               &nbsp;&nbsp;
              <ReportToPrint2
              dateone={this.state.dateone}
              datetwo={this.state.datetwo}
              pending_deliveries={this.state.pending_deliveries}
              ref={el => (this.componentRef_2 = el)} /> 
            </div>


            <Collapse defaultActiveKey={['1']} onChange={this.callback}>
            <Panel header="UMOJA DELIVERIES" key="1">

            <div style={{display:'flex',justifyContent:'flex-start',flexDirection:'row'}}>
                <ReactToPrint
                  trigger={() => 
                  <Button type="primary"  shape="round" icon={<PrinterOutlined />} size={this.state.size}>
                  Print
                  </Button>
                  }
                  content={() => this.componentRef_2}
                  />
                &nbsp;&nbsp;

                <ExcelFile filename={"UmojaIMCouriers -Deliveries from "+this.state.dateone+" to "+this.state.datetwo}  element={<Button type='primary' icon={<DownloadOutlined/>} >Download excel </Button>}>
                <ExcelSheet data={this.state.pending_deliveries} name="Deliveries">
                    <ExcelColumn label="Delivery No" value="delivery_number"/>
                    <ExcelColumn label="Date" value="date"/>
                    <ExcelColumn label="Delivery status" value="delivery_status"/>
                    <ExcelColumn label="Agent" value="agent_account_name"/>
                    <ExcelColumn label="Mode" value="delivery_mode"/>
                    <ExcelColumn label="Pickup" value="pickup_point"/>
                    <ExcelColumn label="Destination" value="destination"/>
                    <ExcelColumn label="Pickup time" value="time_of_pickup"/>
                    <ExcelColumn label="Delivery time" value="time_of_dropoff"/>
                    <ExcelColumn label="Items total" value="items_total"/>

                </ExcelSheet>
               </ExcelFile>

               </div>

            <br></br>
            <br></br>

                 <Form  layout="inline" >

                 <FormItem label="Delivery status">
                     <Select 
                     placeholder="Delivery status"
                     style={{ width: 200 }} 
                     value={this.state.delivery_status} 
                     onChange={(val)=>{this.setState({delivery_status:val})}}
                      
                     showSearch
                     optionFilterProp="children"
                     onFocus={this.onFocus}
                     onBlur={this.onBlur}
                     onSearch={this.onSearch}
                     filterOption={(input, option) =>
                       option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                     }                     
                      >
                        <Option value={"All"}>All </Option>
                        <Option value={'pending'}>Pending</Option>
                        <Option value={'success'}>Success</Option>

                     </Select>
                 </FormItem>


                  <FormItem label="Date Range">
                      <RangePicker onChange={(date, dateString) =>{
                        this.setState({ dateone: dateString[0]});
                        this.setState({ datetwo: dateString[1]});
                      }} format={dateFormat} />
                  </FormItem>

                  <FormItem>
                  <Button onClick={()=>{

                      let form_data = new FormData();
                      form_data.append('dateone', this.state.dateone);
                      form_data.append('datetwo', this.state.datetwo);
                      form_data.append('delivery_status', this.state.delivery_status);
                      form_data.append('hub', localStorage.getItem("coodinated_hub"));

                     if(this.state.dateone===''||this.state.datetwo===''){
                        message.error("Please dates are missing")
                      }else{
                        this.setState({datarequested:true})
                        this.setState({pending_deliveries:[]})
                        this.setState({pending_deliveries_placeholders:[]})
                
                        //Now submit sale data to database
                        axios.post(serverconfig.backendserverurl+'/customqueries/getumoja_deliveriesreport', form_data,{
                        headers: {
                          'content-type': 'multipart/form-data'
                        }
                        })
                        .then(res =>{
                          this.setState({datarequested:false})
                          this.setState({pending_deliveries:JSON.parse(res.data.report)})
                          this.setState({pending_deliveries_placeholders:JSON.parse(res.data.report)})

                        } 
                        )
                        .catch(error => console.log(error))   
                    }

                  }} type="primary" htmlType="button">Search</Button>
                  </FormItem> 
  
             </Form>

            <br></br>

<Form  layout="inline" >

<FormItem label="Filter by agent">
<Search
     placeholder="Filter by agent"
     onChange={(value) => {
       var val=value.target.value
       console.log(val)
       if (val !== '' || val !== undefined) {
         //set one is by NAME
         const results_1 = this.state.pending_deliveries.filter((request) => {
           return String(request.agent_account_name).toLowerCase().startsWith(val.toLowerCase());
           // Use the toLowerCase() method to make it case-insensitive
         });
   
         if (results_1.length === 0) {
          this.setState({pending_deliveries:this.state.pending_deliveries_placeholders});

         } else {
           this.setState({pending_deliveries:results_1});
           console.log(results_1)
         }
   
       } else {
         this.setState({pending_deliveries:this.state.pending_deliveries_placeholders});
       }
   
     }}
     style={{
       width: 200,
       margin:5
     }}
     />
 </FormItem>
 </Form>




          
            <Table 
            columns={columns} 
            scroll={{ x: 1000 }}
            pagination={{showQuickJumper:true,showSizeChanger:true }}
            dataSource={this.state.pending_deliveries}
            bordered
            
            expandable={{
              expandedRowRender: (record) =>{
                  return (
                  <div
                    style={{
                      margin: 0,
                    }}
                    >
                  <div style={{display: "none"}}>
                    &nbsp;&nbsp;
                    <ReportToPrint
                    selected_record={record} 
                    ref={el => (this.componentRef = el)} /> 
                  </div>                        

                  <ReactToPrint
                  trigger={() => 
                  <Button 
                    type="primary"
                    shape="round"
                    icon={<PrinterOutlined />}
                    size={this.state.size}
                    >
                  Print delivery
                  </Button>
                  }
                  content={() => this.componentRef}
                  />
                    <br></br>
                    <br></br>

                        <reactstrp.Table bordered>
                        <thead>
                          <tr>
                            <th>No</th>
                            <th>Item</th>
                            <th>Item type</th>
                            <th>Quantity</th>
                            <th>Description</th>
                            <th>Barcode</th>

                          </tr>
                        </thead>
                        <tbody>
                        {JSON.parse(record.delivery_items).map(
                          (item)=>(
                            <tr>
                              <td>{item.key}</td>
                              <td>{item.item_type}</td>
                              <td>{item.item_name}</td>
                              <td>{item.quantity}</td>
                              <td>{item.description}</td>
                              <td>{item.barcode}</td>

                            </tr>
                          ))}
                          
                      </tbody>
                      </reactstrp.Table>
    
                    </div>
                    );
              } ,
              rowExpandable: (record) => true,
              onExpand:(condition,record)=>{
                this.setState({selected_record:record})
              },
            }}
            
            />

            </Panel>
            </Collapse>
        </div>
    )

    }

   
  }
}

export default Hub_DeliveriesList; 
