import React,{useState,useEffect,lazy} from 'react';
import axios from 'axios'
import {  Slider,Statistic,Tabs,Modal } from 'antd';
import { ArrowUpOutlined,MinusCircleFilled,ToolOutlined, ArrowDownOutlined,FileFilled,WalletFilled,SearchOutlined,FundViewOutlined,
   UserOutlined, AccountBookOutlined,OrderedListOutlined,CalculatorFilled,SwapOutlined,GroupOutlined,AccountBookFilled, DollarCircleOutlined,EditFilled,MessageFilled,DollarOutlined,CarFilled,LoadingOutlined,PlusCircleFilled } from '@ant-design/icons';
import moment from 'moment';
import { Line,Pie,Bar } from 'react-chartjs-2';
import {withGetScreen} from 'react-getscreen'
import { Tooltip,Popover,Table,Popconfirm,Input, Button,Collapse,Card,Avatar,Form,DatePicker,Select,Space,Typography,Divider,InputNumber,message,Spin } from 'antd';
import {
  Col,
  Row,
} from 'reactstrap';
import { CustomTooltips } from '@coreui/coreui-plugin-chartjs-custom-tooltips';
import { getStyle, hexToRgba } from '@coreui/coreui/dist/js/coreui-utilities'
import * as serverconfig from '../../serverconn'
import * as serversocketconfig from '../../serversocketconfig'
import { MdAndroid, MdShoppingCart } from 'react-icons/md';

import CurrencyFormat from 'react-currency-format';
import Highlighter from 'react-highlight-words';
import Websocket from 'react-websocket';
import { Link } from 'react-router-dom';
import ReconnectingWebSocket from 'reconnecting-websocket';
import { NavLink } from 'react-router-dom'
import * as primarycolor from '../../primarycolor'
import QuickMenu from './QuickMenu';

import SportsMotorsportsIcon from '@mui/icons-material/SportsMotorsports';
import BloodtypeSharpIcon from '@mui/icons-material/BloodtypeSharp';
import LocalHospitalSharpIcon from '@mui/icons-material/LocalHospitalSharp';
import Inventory2SharpIcon from '@mui/icons-material/Inventory2Sharp';
import OpacityIcon from '@mui/icons-material/Opacity';
import TwoWheelerSharpIcon from '@mui/icons-material/TwoWheelerSharp';
import OnlineAgentsList from './OnlineAgentsListView';
import OrdersList from './OrdersListView'
import DeliveriesList from './DeliveriesListView'
import AgentActivityMap from './AgentActivityMap'
import SampleBarCodeForm from '../components/SampleBarCodeForm'

var CryptoJS = require("crypto-js");

const { TextArea } = Input;
const antIcon = <LoadingOutlined style={{ fontSize: 40 }} spin />;


const style = { background: '#0092ff', padding: '8px 0',borderRadius: '7px',justifyContent:'center', alignItems:'center',display: 'flex', };
const dateFormat = 'DD/MM/YYYY';
const yeardateFormat = 'YYYY';
const monthdateFormat = 'MM/YYYY';

const { Meta } = Card;
const { Panel } = Collapse;
const { TabPane } = Tabs;
const FormItem=Form.Item;
const { Option } = Select;


var bizuserid= ''
var instance_id= ''
var token= ''
var notificationsocket = new ReconnectingWebSocket(serversocketconfig.socketurl+'/ws/notificationsocket/');
var agentlocationsocket = new ReconnectingWebSocket(serversocketconfig.socketurl+'/ws/agentlocationsocket/');


class MainControlDashBoard extends React.Component {

  state = {
    currentmonth:moment().format(monthdateFormat).toString(),
    companyprofile:{},
    datarequested:true,
    memberformvisible:false,
    transactformvisible:false,
    calculatorformvisible:false,
    members:[],
    loansformvisible:false,
    dashboardlocked:false,
    utilitiesmodalvisible:false,
    userprofile:{},
    userrights:{},
    nonworkingday:{},
    depositsno:0,
    withdrawsno:0,
    depositvolume:0,
    withdrawvolume:0,
    tranquerymode:'Daily',
    expensevolumes:0,
    incomevolumes:0,
    datareload:false,
    withdrawpercentage:0,
    withdrwdiff:0,
    depositpercentage:0,
    depositdiff:0,
    percenategincomes:0,
    incomediff:0,
    percenategexpnses:0,
    expensdiff:0,
    weeklydays:[],
    weeklydeposits:[],
    weeklywithdraws:[],
    weeklyincomes:[],
    weeklyexpenses:[],

    weeklychartdata:[],
    weeklychartitems:[],

    graphtranquerymode:'weekly',
    loanproccessuerymode:'daily',

    totalapplications:0,
    totalapplicationamounts:0,
    totalassessments:0,
    totalassessmentsamounts:0,
    totalapprovals:0,
    totalapprovalsamounts:0,
    totaldisbursements:0,
    totaldisbursementsamounts:0,

    projectedmonths:"three",
    monthsprojected:[],
    projectedinterest:[],
    projectedprinciple:[],

    applicationvsdates:[],
    applicationvs:[],
    issuancesvs:[],
    applicvsmode:'weekly',

    total_of_agents:0,

    healthcentres:[],
    collectionhubs:[],
    samples:[],
    supplies:[],
    healthcentres:[],
    delivery_agents:[],
    pending_deliveries:[],
    products:[],
    delivery_agents_all:[],
    app_clients:[]

  };



  callback =(key) =>{
    console.log(key);
    console.log(this.state.yearlymonthlysales)
  }

  componentDidMount(){

    if(localStorage.getItem("instance_id")){
      instance_id= localStorage.getItem("instance_id")
    }else{
      instance_id= ''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }


    if(localStorage.getItem("bizuserid")){
      bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
   }else{
      bizuserid= ''
   }
    
    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

   axios.get(serverconfig.backendserverurl+`/api/accounts/${bizuserid}`)
   .then(res => {
       this.setState({
        userprofile:res.data
       })
   })


   axios.get(serverconfig.backendserverurl+`/api/healthcentres`)
   .then(res => {
       this.setState({healthcentres:res.data})
   })

   axios.get(serverconfig.backendserverurl+`/api/collectionhubs`)
   .then(res => {
       this.setState({collectionhubs:res.data})
   })

   axios.get(serverconfig.backendserverurl+`/api/samples`)
   .then(res => {
       this.setState({samples:res.data})

   })

   axios.get(serverconfig.backendserverurl+`/api/supplies`)
   .then(res => {
       this.setState({supplies:res.data})
   })


   axios.get(serverconfig.backendserverurl+`/api/delivery_agents/`)
   .then(res => {
       this.setState({delivery_agents_all:res.data})
   })

   axios.get(serverconfig.backendserverurl+`/api/app_clients/`)
   .then(res => {
       this.setState({app_clients:res.data})
   })




   axios.get(serverconfig.backendserverurl+`/api/products`)
   .then(res => {
       this.setState({products:res.data})
   })


   axios.get(serverconfig.backendserverurl+`/api/pending_deliveries/?trip_status=${"ongoing"}`)
   .then(res => {
       this.setState({pending_deliveries:res.data})
   })


   axios.get(serverconfig.backendserverurl+`/api/app_profile/${1}`)
   .then(res => {
       this.setState({
         companyprofile:res.data
       })

       this.setState({datarequested:false})
   })

   axios.get(serverconfig.backendserverurl+`/api/accountrights/?user=${bizuserid}`)
   .then(res => {
      console.log("data: "+res.data[0] )
       this.setState({
         userrights:res.data[0]
       })
   })


   notificationsocket.onmessage = async (e) => {
    axios.get(serverconfig.backendserverurl+`/api/pending_deliveries/?trip_status=${'ongoing'}`)
    .then(res => {
        this.setState({
            pending_deliveries:res.data
        })
    })

  };


  axios.get(serverconfig.backendserverurl+`/api/delivery_agents/?is_online=${true}&active=${true}`)
  .then(res => {
      this.setState({
        delivery_agents:res.data
      })
  })


    //rerold on agnet location change
    agentlocationsocket.onmessage = async (e) => {
      //just update agent location

      axios.get(serverconfig.backendserverurl+`/api/delivery_agents/?is_online=${true}&active=${true}`)
      .then(res => {
          this.setState({
            delivery_agents:res.data
          })  
      }) 

    };

}

render() {

if(this.state.dashboardlocked===true){
  return(
    <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
    <h3 style={{fontWeight:'bolder',color:'red'}}>NOTE: Your dashboard is currently locked. Contact system adminstrators.Your Licence may be expired or some thing else. Thanks</h3>
    </div>
  )
}else{

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )
     }else{

        return(
         <div className="animated fadeIn">  
        <Tabs>


          {
            this.state.userrights.view_statistics===true && this.state.userprofile.branch_manager===true?
            <TabPane tab="Statistics" key="01" >

            <Row>
     
               <Col xs="12" sm="6" lg="3" style={{padding: '5px'}}>
               <NavLink to='/deliveryagents/'> 
               <Card style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                   <div style={{display:'flex',alignItems:'center',justifyContent:'center',alignContent:'center',flexDirection:'column'}}>
                   
                     <SportsMotorsportsIcon style={{color:primarycolor.primarycolor,fontSize: 50}} />
     
                     <h6 style={{display:'flex',fontSize:22,fontWeight:'bold'}}>{this.state.delivery_agents_all.length}</h6>
                     <h6 style={{display:'flex',marginRight:5,fontSize:16}}> Total of Agents </h6>
     
                   </div>
                 </Card>
               </NavLink>
               </Col>


               <Col xs="12" sm="6" lg="3" style={{padding: '5px'}}>
               <NavLink to='/app_clients/'> 
               <Card style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                   <div style={{display:'flex',alignItems:'center',justifyContent:'center',alignContent:'center',flexDirection:'column'}}>
                   
                     <MdAndroid style={{color:primarycolor.primarycolor,fontSize: 50}} />
     
                     <h6 style={{display:'flex',fontSize:22,fontWeight:'bold'}}>{this.state.app_clients.length}</h6>
                     <h6 style={{display:'flex',marginRight:5,fontSize:16}}> Total of App Clients </h6>
     
                   </div>
                 </Card>
               </NavLink>
               </Col>


     
               <Col xs="12" sm="6" lg="3" style={{padding: '5px'}}>
               <NavLink to='/collectionhubs/'> 
               <Card style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                   <div style={{display:'flex',alignItems:'center',justifyContent:'center',alignContent:'center',flexDirection:'column'}}>
                   
                     <BloodtypeSharpIcon style={{color:primarycolor.primarycolor,fontSize: 50}} />
     
                     <h6 style={{display:'flex',fontSize:22,fontWeight:'bold'}}>{this.state.collectionhubs.length}</h6>
                     <h6 style={{display:'flex',marginRight:5,fontSize:16}}> Total of Collection Hubs </h6>
     
                   </div>
                 </Card>
               </NavLink>
               </Col>
     
     
               <Col xs="12" sm="6" lg="3" style={{padding: '5px'}}>
               <NavLink to='/healthcenters/'> 
               <Card style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                   <div style={{display:'flex',alignItems:'center',justifyContent:'center',alignContent:'center',flexDirection:'column'}}>
                   
                     <LocalHospitalSharpIcon style={{color:primarycolor.primarycolor,fontSize: 50}} />
     
                     <h6 style={{display:'flex',fontSize:22,fontWeight:'bold'}}>{this.state.healthcentres.length}</h6>
                     <h6 style={{display:'flex',marginRight:5,fontSize:16}}> Total of Health Centers </h6>
     
                   </div>
                 </Card>
               </NavLink>
               </Col>
     
     
               <Col xs="12" sm="6" lg="3" style={{padding: '5px'}}>
               <NavLink to='/supplies/'> 
               <Card style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                   <div style={{display:'flex',alignItems:'center',justifyContent:'center',alignContent:'center',flexDirection:'column'}}>
                   
                     <Inventory2SharpIcon style={{color:primarycolor.primarycolor,fontSize: 50}} />
     
                     <h6 style={{display:'flex',fontSize:22,fontWeight:'bold'}}>{this.state.supplies.length}</h6>
                     <h6 style={{display:'flex',marginRight:5,fontSize:16}}> Total of supplies </h6>
     
                   </div>
                 </Card>
               </NavLink>
               </Col>
     
     
               <Col xs="12" sm="6" lg="3" style={{padding: '5px'}}>
               <NavLink to='/samples/'> 
               <Card style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                   <div style={{display:'flex',alignItems:'center',justifyContent:'center',alignContent:'center',flexDirection:'column'}}>
                   
                     <OpacityIcon style={{color:primarycolor.primarycolor,fontSize: 50}} />
     
                     <h6 style={{display:'flex',fontSize:22,fontWeight:'bold'}}>{this.state.samples.length}</h6>
                     <h6 style={{display:'flex',marginRight:5,fontSize:16}}> Total of samples </h6>
     
                   </div>
                 </Card>
               </NavLink>
               </Col>
     
     
               <Col xs="12" sm="6" lg="3" style={{padding: '5px'}}>
               <NavLink to='/products/'> 
               <Card style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                   <div style={{display:'flex',alignItems:'center',justifyContent:'center',alignContent:'center',flexDirection:'column'}}>
                   
                     <OrderedListOutlined style={{color:primarycolor.primarycolor,fontSize: 50}} />
     
                     <h6 style={{display:'flex',fontSize:22,fontWeight:'bold'}}>{this.state.products.length}</h6>
                     <h6 style={{display:'flex',marginRight:5,fontSize:16}}> Total of products </h6>
     
     
                   </div>
                 </Card>
               </NavLink>
               </Col>
     
     
               </Row>
     
     
     
             </TabPane>
            :
            null
          }


        {
         this.state.userrights.view_umoja_deliveries===true?
        <TabPane tab="Hub to Healthcenter deliveries" key="02" >
          <DeliveriesList />
        </TabPane>
        :
        null}


{
         this.state.userrights.view_product_orders===true?
        <TabPane tab="Products orders" key="03" >
          <OrdersList />
        </TabPane>
        :
        null}


{
         this.state.userrights.view_online_riders===true?

        <TabPane tab={"Online agents ("+this.state.delivery_agents.length+" )"} key="04" >
          <OnlineAgentsList />
        </TabPane>:
        null}

{
         this.state.userrights.view_agent_activities===true?

        <TabPane tab={"Agent activity track map/report"} key="05">
          <AgentActivityMap />
        </TabPane>
        :
        null}


{
         this.state.userrights.view_barcodes===true?
        <TabPane tab={"Barcodes"} key="06">
          <SampleBarCodeForm />
        </TabPane>
        :
        null}


        </Tabs>

          </div>
      )
    }
  
 }

}
}

export default MainControlDashBoard; 
