import React from 'react';
import SampleForm from '../components/SampleForm'
import axios from 'axios'
import { Table, Input, Button,Collapse,Popover,Popconfirm,Form,message,Tabs,Spin,Modal,Select,Result,DatePicker } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon from '@ant-design/icons';
import { SearchOutlined,DeleteOutlined,FundViewOutlined,DownloadOutlined,PrinterOutlined,LoadingOutlined,EditFilled } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import * as serverconfig from '../../serverconn'
import CurrencyFormat from 'react-currency-format';
import * as reactstrp from 'reactstrap';
import ReactExport from "@ibrahimrahmani/react-export-excel";
import moment from 'moment';
import ReactToPrint from 'react-to-print';
import {
  Col,
  Row,
} from 'reactstrap';

import PolylineMap_Other from '../components/PolylineMap_Other';
import OrderUpdateForm from './OrderUpdateForm'



const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


const { Panel } = Collapse;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const FormItem=Form.Item;
var token= ''
const { Search } = Input;
const { Option } = Select;

const dateFormat = 'DD/MM/YYYY';
const { RangePicker } = DatePicker;
const { TabPane } = Tabs;


class ReportToPrint extends React.Component {

  state = {
    companyprofile:{},
  };

  componentDidMount(){

    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(serverconfig.backendserverurl+`/api/app_profile/${1}`)
    .then(res => {  
        this.setState({
          companyprofile: res.data
        })

    })


  }

  //clactulate totla of items
  calculateTotalofItems=()=>{
    var total=0

    JSON.parse(this.props.selected_record.item_details).map(
      (item)=>{
        total+=Number(item.total)
      })

    return  total.toFixed(2);  

  }



  render() {
    return (
      <div style={{padding:20}}>
            <Row> 
              <Col xs="2" sm="2" lg="2">
                <img height="100" width="120"  alt="Logo" src={this.state.companyprofile.company_logo} />
                </Col>
                <Col xs="6" sm="6" lg="6">
                <h3 style={{fontWeight:'bolder'}}>{this.state.companyprofile.system_name}</h3>
                <h6 style={{fontWeight:'bolder'}}>Tel: {this.state.companyprofile.RegisteredPhoneNumber1}</h6>               
                <h5 style={{fontWeight:'bolder'}}>Email: {this.state.companyprofile.company_email}</h5>
              
                </Col>
            </Row>

            <h2 style={{display: 'flex',justifyContent:'center', alignItems:'center',fontWeight:'bolder'}}>CLIENT ORDER DETAILS</h2>

            <h4 style={{fontWeight:'bolder'}}align='left'>{' ORDER NUMBER: '+this.props.selected_record.ordernumber}</h4>
            <h4 style={{fontWeight:'bolder'}}align='left'>{' DATE: '+this.props.selected_record.date}</h4>
            <h4 style={{fontWeight:'bolder'}}align='left'>{' AGENT: '+this.props.selected_record.assigned_agentname}</h4>
            <h4 style={{fontWeight:'bolder'}}align='left'>{' PAY MODE: '+this.props.selected_record.mode_of_payment}</h4>
            <h4 style={{fontWeight:'bolder'}}align='left'>{' DELIVERY ADDRESS: '+this.props.selected_record.order_address}</h4>

            <h4 style={{fontWeight:'bolder'}}align='left'>{' EXTRA ADDRESS INFO: '+this.props.selected_record.additional_addressinfo}</h4>
            <h4 style={{fontWeight:'bolder'}}align='left'>{' TIME OF PICKUP: '+this.props.selected_record.time_of_pickup}</h4>
            <h4 style={{fontWeight:'bolder'}}align='left'>{' TIME OF DELIVERY: '+this.props.selected_record.time_of_dropoff}</h4>

            <h4 style={{fontWeight:'bolder'}}align='left'>{' ORDER TOTAL: '} <CurrencyFormat value={this.props.selected_record.order_total} displayType={'text'} thousandSeparator={true}/> </h4>
            <h4 style={{fontWeight:'bolder'}}align='left'>{' EXTRA CHARGE: '}  <CurrencyFormat value={this.props.selected_record.additional_charges} displayType={'text'} thousandSeparator={true}/></h4>
            <h4 style={{fontWeight:'bolder'}}align='left'>{' TOTAL: '}  <CurrencyFormat value={Number(this.props.selected_record.order_total)+Number(this.props.selected_record.additional_charges)} displayType={'text'} thousandSeparator={true}/></h4>
            <h4 style={{fontWeight:'bolder'}}align='left'>{' STATUS: '+this.props.selected_record.order_status}</h4>


            <reactstrp.Table bordered>
                <thead>
                  <tr>
                      <th>#</th>
                    <th>Item</th>
                    <th>Quantity</th>
                    <th>Description</th>
                    <th>Amount</th>
                    
                  </tr>
                </thead>
                <tbody>
                { JSON.parse(this.props.selected_record.item_details).map(
                  (item)=>(
                    <tr>
                      <td>{item.key}</td>
                      <td>{item.item_name}</td>
                      <td>{item.quantity}</td>
                      <td>{item.description}</td>
                      <td>{<CurrencyFormat value={item.total} displayType={'text'} thousandSeparator={true}/>}</td>

                    </tr>
                  ))}
                      <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>{<CurrencyFormat value={this.calculateTotalofItems()} displayType={'text'} thousandSeparator={true}/>}</td>

                    </tr>
                  
              </tbody>
              </reactstrp.Table>

      </div>
    );
  }
}






class ReportToPrint2 extends React.Component {

  state = {
    companyprofile:{},
  };

  componentDidMount(){

    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(serverconfig.backendserverurl+`/api/app_profile/${1}`)
    .then(res => {  
        this.setState({
          companyprofile: res.data
        })

    })

  }

  //clactulate totla of items
  calculateTotalofItems_items=()=>{
    var total=0

    this.props.clientorders.map(
      (item)=>{
        total+=Number(item.order_total)
      })

    return  total.toFixed(2);  

  }



    //clactulate totla of items
    calculateTotalofItems_extra=()=>{
      var total=0
  
      this.props.clientorders.map(
        (item)=>{
          total+=Number(item.additional_charges)
        })
  
      return  total.toFixed(2);  
  
    }

    calculateTotalofItems_Total=()=>{
      var total=0
  
      this.props.clientorders.map(
        (item)=>{
          total+=(Number(item.additional_charges)+Number(item.order_total))
        })
  
      return  total.toFixed(2);  
  
    }
  
  



  render() {
    return (
      <div style={{padding:20}}>
            <Row> 
              <Col xs="2" sm="2" lg="2">
                <img height="100" width="120"  alt="Logo" src={this.state.companyprofile.company_logo} />
                </Col>
                <Col xs="6" sm="6" lg="6">
                <h3 style={{fontWeight:'bolder'}}>{this.state.companyprofile.system_name}</h3>
                <h6 style={{fontWeight:'bolder'}}>Tel: {this.state.companyprofile.RegisteredPhoneNumber1}</h6>               
                <h5 style={{fontWeight:'bolder'}}>Email: {this.state.companyprofile.company_email}</h5>
              
                </Col>
            </Row>

            <h2 style={{display: 'flex',justifyContent:'center', alignItems:'center',fontWeight:'bolder'}}>CLIENT ORDERS FROM {this.props.dateone} TO {this.props.datetwo}</h2>


               <reactstrp.Table bordered>
                    <thead>
                    <tr>
                    <th>Order No</th>
                    <th>Date</th>
                    <th>Order status</th>
                    <th>Client</th>
                    <th>Assigned agent</th>
                    <th>Delivery address</th>
                    <th>More address info</th>
                    <th>Pickup time</th>
                    <th>Delivery time</th>
                    <th>Items total</th>

                    <th>Items total amount</th>
                    <th>Extra charge</th>
                    <th>Total</th>

                    </tr>
                    </thead>
                    <tbody>
                    { this.props.clientorders.map(
                      (item)=>(
                        <tr>
                         <td>{item.ordernumber}</td>
                         <td>{item.date}</td>
                         <td>{item.order_status}</td>
                         <td>{item.clientname}</td>
                         <td>{item.assigned_agentname}</td>
                         <td>{item.order_address}</td>
                         <td>{item.additional_addressinfo}</td>
                         <td>{item.time_of_pickup}</td>
                         <td>{item.time_of_dropoff}</td>
                         <td>{item.items_total}</td>

                         <td>{<CurrencyFormat value={Number(item.order_total)} displayType={'text'} thousandSeparator={true}  />}</td>
                         <td>{<CurrencyFormat value={Number(item.additional_charges)} displayType={'text'} thousandSeparator={true}  />}</td>
                         <td>{<CurrencyFormat value={Number(item.order_total)+Number(item.additional_charges)} displayType={'text'} thousandSeparator={true}  />}</td>

                        </tr>
                      ))}


                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>

                      <td>{<CurrencyFormat value={this.calculateTotalofItems_items()} displayType={'text'} thousandSeparator={true}/>}</td>
                      <td>{<CurrencyFormat value={this.calculateTotalofItems_extra()} displayType={'text'} thousandSeparator={true}/>}</td>
                      <td>{<CurrencyFormat value={this.calculateTotalofItems_Total()} displayType={'text'} thousandSeparator={true}/>}</td>

                    </tr>


                  </tbody>
              </reactstrp.Table> 
      </div>
    );
  }
}




var token= ''
class OrdersList extends React.Component {
  state = {
    searchText: '',
    searchedColumn: '',
    clientorders: [],
    datarequested:true,
    selected_record:{},
    show_assignmentModel:false,
    delivery_agents: [],
    selected_agent:'',
    show_xtrachargeModel:false,
    extra_charge:0,

    dateone:moment().format(dateFormat).toString(),
    datetwo:moment().format(dateFormat).toString(),
    order_status:'All'

  };

  componentDidMount(){
    this.compo_loaded()
  }



compo_loaded=()=>{

  if(localStorage.getItem("token")){
    token= localStorage.getItem("token")
 }else{
    token= ''
 }

 axios.defaults.headers={
   "Content-Type":"application/json",
   Authorization:`Token ${token}`
 }

 /*
 axios.get(serverconfig.backendserverurl+`/api/clientorders`)
 .then(res => {
     this.setState({
         clientorders:res.data
     })
     this.setState({datarequested:false})
 }) */


 let form_data = new FormData();
 form_data.append('dateone', this.state.dateone);
 form_data.append('datetwo', this.state.datetwo);
 form_data.append('order_status', this.state.order_status);
if(this.state.dateone===''||this.state.datetwo===''){
   message.error("Please dates are missing")
 }else{
   this.setState({datarequested:true})
   this.setState({clientorders:[]})
   this.setState({clientorders_placeholders:[]})

   //Now submit sale data to database
   axios.post(serverconfig.backendserverurl+'/customqueries/getclientorder_deliveriesreport', form_data,{
   headers: {
     'content-type': 'multipart/form-data'
   }
   })
   .then(res =>{
     this.setState({datarequested:false})
     this.setState({clientorders:JSON.parse(res.data.report)})
     this.setState({clientorders_placeholders:JSON.parse(res.data.report)})

   })
   .catch(error => console.log(error)) 
 } 


 //get agents
 axios.get(serverconfig.backendserverurl+`/api/delivery_agents/?is_online=${true}`)
 .then(res => {
     this.setState({
       delivery_agents:res.data
     })
 })






}




  //clactulate totla of items
  calculateTotalofItems_items=()=>{
    var total=0

    this.state.clientorders.map(
      (item)=>{
        total+=Number(item.order_total)
      })

    return  total.toFixed(2);  

  }



    //clactulate totla of items
    calculateTotalofItems_extra=()=>{
      var total=0
  
      this.state.clientorders.map(
        (item)=>{
          total+=Number(item.additional_charges)
        })
  
      return  total.toFixed(2);  
  
    }

    calculateTotalofItems_Total=()=>{
      var total=0
  
      this.state.clientorders.map(
        (item)=>{
          total+=(Number(item.additional_charges)+Number(item.order_total))
        })
  
      return  total.toFixed(2);  
  
    }
  
  



getColumnSearchProps = dataIndex => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    <div style={{ padding: 8 }}>
      <Input
        ref={node => {
          this.searchInput = node;
        }}
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
      <Button
        type="primary"
        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        icon={<SearchOutlined />}
        size="small"
        style={{ width: 90, marginRight: 8 }}
      >
        Search
      </Button>
      <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
        Reset
      </Button>
    </div>
  ),
  filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilter: (value, record) =>
    record[dataIndex]
      .toString()
      .toLowerCase()
      .includes(value.toLowerCase()),
  onFilterDropdownVisibleChange: visible => {
    if (visible) {
      setTimeout(() => this.searchInput.select());
    }
  },
  render: text =>
    this.state.searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ) : (
      text
    ),
});

handleSearch = (selectedKeys, confirm, dataIndex) => {
  confirm();
  this.setState({
    searchText: selectedKeys[0],
    searchedColumn: dataIndex,
  });
};

handleReset = clearFilters => {
  clearFilters();
  this.setState({ searchText: '' });
};

 

  render() {

    const columns = [
      {
        title: 'Order no',
        dataIndex: 'ordernumber',
        key: 'id',
        ...this.getColumnSearchProps('ordernumber'),
      },

      {
        title: 'Status',
        dataIndex: 'order_status',
        key: 'id',
        ...this.getColumnSearchProps('order_status'),
        render: (text,record) =>
        <p style={{color:record.order_status==="pending"?"orange":record.order_status==="cancelled"?"red":"green"}}>
           {text}
        </p>
      },
      {
        title: 'date',
        dataIndex: 'date',
        key: 'id',
        ...this.getColumnSearchProps('date'),

      },

      {
        title: 'Client',
        dataIndex: 'clientname',
        key: 'id',
        ...this.getColumnSearchProps('clientname'),
      },

      {
        title: 'Delivery Address',
        dataIndex: 'order_address',
        key: 'id',
      
      },
      {
        title: 'Additional delivery address info',
        dataIndex: 'additional_addressinfo',
        key: 'id',
      },

      {
        title: 'Assigned Agent',
        dataIndex: 'assigned_agentname',
        key: 'id',
        ...this.getColumnSearchProps('assigned_agentname'),
      },
      {
        title: 'Time of pickup ',
        dataIndex: 'time_of_pickup',
        key: 'id',
      },
      
      {
        title: 'Time of delivery ',
        dataIndex: 'time_of_dropoff',
        key: 'id',
      },

      {
        title: 'Total Amount',
        dataIndex: 'order_total',
        key: 'id',
        render:text=><CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}  />
      },
      {
        title: 'Extra charges',
        dataIndex: 'additional_charges',
        key: 'id',
        render:text=><CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}  />
      },
      {
        title: 'Items total ',
        dataIndex: 'items_total',
        key: 'id',
      },
      {
        title: 'Action',
        dataIndex: 'id',
        key: 'id',
        render: (text,record) =>
        <p>
          
        {
          record.order_status==="pending"?
          <Popover content={<p>Assign agent</p>} title="Assign agent">
          <EditFilled style={{color:'green'}} 
            onClick={()=>{
            this.setState({show_assignmentModel:true})
            this.setState({selected_record:record})
            
            }} />
          </Popover>
          :
          null
        }

        &nbsp;&nbsp;&nbsp;

        {
          record.order_status==="pending"?


          <Popover content={<p>Click here to cancel order</p>} title="Cancel">
          <Popconfirm title="Sure to delete?"
          
           onConfirm={() => {
              this.setState({datarequested:true})

              let form_data = new FormData();
              form_data.append('order_id', record.id);

              axios.post(serverconfig.backendserverurl+`/customqueries/cancel_order`, form_data, {
                headers: {
                  'content-type': 'multipart/form-data'
                }
              })
              .then(async(res) =>{
                message.info(res.data.message)
                this.setState({datarequested:false})

              })
              .catch(error => console.log(error)) 

          }}>
            <DeleteOutlined style={{color:'red'}}/>
           </Popconfirm>
          </Popover>

          :
          null
        }
              &nbsp;&nbsp;&nbsp;


        {
          record.order_status==="assigned"?
          <Popover content={<p>Apply xtra charge</p>} title="Extra charge">
          <EditFilled style={{color:'blue'}} 
            onClick={()=>{
            this.setState({show_xtrachargeModel:true})
            this.setState({selected_record:record})
            
            }} />
          </Popover>
          :
          null
        }

        </p>,
      }


      
    ];

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin size="large">
        <div className="content">
          <h3>Umoja couriers</h3>
        </div>
         </Spin> 
          </div>
      )

    }else{
      return(
        <div>
            <Collapse defaultActiveKey={['1']} onChange={this.callback}>
            <Panel header="ALL ORDERS" key="1">
          
            <div style={{display: "none"}}>
               &nbsp;&nbsp;
              <ReportToPrint2
              dateone={this.state.dateone}
              datetwo={this.state.datetwo}
              clientorders={this.state.clientorders}
              ref={el => (this.componentRef_2 = el)} /> 
            </div>


            <div style={{display:'flex',justifyContent:'flex-start',flexDirection:'row'}}>
                <ReactToPrint
                  trigger={() => 
                  <Button type="primary"  shape="round" icon={<PrinterOutlined />} size={this.state.size}>
                  Print
                  </Button>
                  }
                  content={() => this.componentRef_2}
                  />
                &nbsp;&nbsp;

                <ExcelFile filename={"UmojaIMCouriers -Orders from "+this.state.dateone+" to "+this.state.datetwo}  element={<Button type='primary' icon={<DownloadOutlined/>} >Download excel </Button>}>
                <ExcelSheet data={this.state.clientorders} name="Client Orders">
                    <ExcelColumn label="Order No" value="ordernumber"/>
                    <ExcelColumn label="Date" value="date"/>
                    <ExcelColumn label="Order status" value="order_status"/>
                    <ExcelColumn label="Client" value="clientname"/>
                    <ExcelColumn label="Assigned agent" value="assigned_agentname"/>

                    <ExcelColumn label="Delivery Address" value={'order_address'}/>
                    <ExcelColumn label="Extra address info" value="additional_addressinfo"/>
                    <ExcelColumn label="Pickup time" value="time_of_pickup"/>
                    <ExcelColumn label="Delivery time" value="time_of_dropoff"/>

                    <ExcelColumn label="Order total" value="order_total"/>
                    <ExcelColumn label="Extra charges" value="additional_charges"/>
                    <ExcelColumn label="Items total" value="items_total"/>

                </ExcelSheet>
               </ExcelFile>

               </div>

            <br></br>
            <br></br>

                 <Form  layout="inline" >
                 <FormItem label="Order status">
                     <Select 
                     placeholder="Order status"
                     style={{ width: 200 }} 
                     value={this.state.order_status} 
                     onChange={(val)=>{this.setState({order_status:val})}}
                      
                     showSearch
                     optionFilterProp="children"
                     onFocus={this.onFocus}
                     onBlur={this.onBlur}
                     onSearch={this.onSearch}
                     filterOption={(input, option) =>
                       option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                     }                     
                      >
                        <Option value={"All"}>All </Option>
                        <Option value={'pending'}>Pending</Option>
                        <Option value={'success'}>Success</Option>
                        <Option value={'cancelled'}>Cancelled</Option>
                        <Option value={'assigned'}>Assigned</Option>

                     </Select>
                 </FormItem>

                  <FormItem label="Date Range">
                      <RangePicker onChange={(date, dateString) =>{
                        this.setState({ dateone: dateString[0]});
                        this.setState({ datetwo: dateString[1]});
                      }} format={dateFormat} />
                  </FormItem>

                  <FormItem>
                  <Button onClick={()=>{

                      let form_data = new FormData();
                      form_data.append('dateone', this.state.dateone);
                      form_data.append('datetwo', this.state.datetwo);
                      form_data.append('order_status', this.state.order_status);

                     if(this.state.dateone===''||this.state.datetwo===''){
                        message.error("Please dates are missing")
                      }else{
                        this.setState({datarequested:true})
                        this.setState({clientorders:[]})
                        this.setState({clientorders_placeholders:[]})
                
                        //Now submit sale data to database
                        axios.post(serverconfig.backendserverurl+'/customqueries/getclientorder_deliveriesreport', form_data,{
                        headers: {
                          'content-type': 'multipart/form-data'
                        }
                        })
                        .then(res =>{
                          this.setState({datarequested:false})
                          this.setState({clientorders:JSON.parse(res.data.report)})
                          this.setState({clientorders_placeholders:JSON.parse(res.data.report)})

                        } 
                        )
                        .catch(error => console.log(error))   
                    }

                  }} type="primary" htmlType="button">Search</Button>
                  </FormItem> 
  
             </Form>

            <br></br>

            <Form  layout="inline" >

            <FormItem label="Filter by agent">
            <Search
                placeholder="Filter by agent"
                onChange={(value) => {
                  var val=value.target.value
                  console.log(val)
                  if (val !== '' || val !== undefined) {
                    //set one is by NAME
                    const results_1 = this.state.clientorders.filter((request) => {
                      return String(request.assigned_agentname).toLowerCase().startsWith(val.toLowerCase());
                      // Use the toLowerCase() method to make it case-insensitive
                    });
              
                    if (results_1.length === 0) {
                      this.setState({clientorders:this.state.clientorders_placeholders});

                    } else {
                      this.setState({clientorders:results_1});
                      console.log(results_1)
                    }
              
                  } else {
                    this.setState({clientorders:this.state.clientorders_placeholders});
                  }
              
                }}
                style={{
                  width: 200,
                  margin:5
                }}
                />
            </FormItem>

            <FormItem label="Filter by client">
            <Search
                placeholder="Filter by client"
                onChange={(value) => {
                  var val=value.target.value
                  console.log(val)
                  if (val==='' || val===undefined || val===null){
                    this.setState({clientorders:this.state.clientorders_placeholders});

                  }


                  if (val !== '' || val !== undefined) {
                    //set one is by NAME
                    const results_1 = this.state.clientorders.filter((request) => {
                      return String(request.clientname).toLowerCase().startsWith(val.toLowerCase());
                      // Use the toLowerCase() method to make it case-insensitive
                    });
              
                    if (results_1.length === 0) {
                      this.setState({clientorders:this.state.clientorders_placeholders});

                    } else {
                      this.setState({clientorders:results_1});
                      console.log(results_1)
                    }
              
                  } else {
                    this.setState({clientorders:this.state.clientorders_placeholders});
                  }
              
                }}
                style={{
                  width: 200,
                  margin:5
                }}
                />
            </FormItem>

            </Form>

            <br></br>

            <Table 
            columns={columns} 
            scroll={{ x: 1000 }}
            pagination={{showQuickJumper:true,showSizeChanger:true }}
            dataSource={this.state.clientorders}
            bordered
            
            expandable={{
              expandedRowRender: (record) =>{
                  return (
                    <div
                      style={{
                        margin: 0,
                      }}
                      >

                  <div style={{display: "none"}}>
                    &nbsp;&nbsp;
                    <ReportToPrint
                    selected_record={record} 
                    ref={el => (this.componentRef = el)} /> 
                  </div> 

                  <ReactToPrint
                  trigger={() => 
                  <Button 
                    type="primary"
                    shape="round"
                    icon={<PrinterOutlined />}
                    size={this.state.size}
                    >
                  Print Order
                  </Button>
                  }
                  content={() => this.componentRef}
                  />

                  <br></br>
                  <br></br>
                  <Tabs>

                  <TabPane tab="Items" key="01" >

                        <reactstrp.Table bordered>
                        <thead>
                          <tr>
                              <th>#</th>
                            <th>Item</th>
                            <th>Quantity</th>
                            <th>Description</th>
                            <th>Amount</th>

                          </tr>
                        </thead>
                        <tbody>
                        {JSON.parse(record.item_details).map(
                          (item)=>(
                            <tr>
                              <td>{item.key}</td>
                              <td>{item.item_name}</td>
                              <td>{item.quantity}</td>
                              <td>{item.description}</td>
                              <td>{item.total}</td>

                            </tr>
                          ))}
                          
                      </tbody>
                      </reactstrp.Table>
                  </TabPane>

                  <TabPane tab="Delivery address" key="02" >
                    <PolylineMap_Other initialCoordinates={JSON.parse(record.order_address_dictionary)} />
                  </TabPane>

                  <TabPane tab="Order Update" key="03" >
                    <OrderUpdateForm onRefresh={this.compo_loaded} order_obj={record} />
                
                  </TabPane>

                  </Tabs>
    
                   </div>
                   );
              } ,
              rowExpandable: (record) => true,
              onExpand:(condition,record)=>{
              },
            }}
            
            />
            <br></br>
            <div style={{display:'flex',flexDirection:'row'}}>
              <h4 style={{display:'flex',margin:4}}>Total orders: {this.state.clientorders.length}</h4>
              <h4 style={{display:'flex',margin:4}}>Items total amount: {<CurrencyFormat value={this.calculateTotalofItems_items()} displayType={'text'} thousandSeparator={true}  />}</h4>
              <h4 style={{display:'flex',margin:4}}>Extra total amount: {<CurrencyFormat value={this.calculateTotalofItems_extra()} displayType={'text'} thousandSeparator={true}  />}</h4>
              <h4 style={{display:'flex',margin:4}}>Overall total amount: {<CurrencyFormat value={this.calculateTotalofItems_Total()} displayType={'text'} thousandSeparator={true}  />}</h4>

            </div>

            </Panel>
            </Collapse>


          <Modal              
              visible={this.state.show_assignmentModel}
              title="Agent assignment form"
              width={600}
              onCancel={()=>{this.setState({show_assignmentModel:false})}}

              footer={[
                <Button key="back" onClick={()=>{this.setState({show_assignmentModel:false})}}>
                  Cancel
                </Button>
                ]}
                >

                <FormItem label="Online agents">
                  <Select
                    placeholder="Agent"
                    style={{  }} 
                    value={this.state.selected_agent}
                      onChange={(val)=>{this.setState({selected_agent:val})}} 
                      showSearch
                      optionFilterProp="children"
                      onFocus={this.onFocus}
                      onBlur={this.onBlur}
                      onSearch={this.onSearch}
                      >
                    {this.state.delivery_agents.map(
                      (item)=>(
                        <Option value={item.id}>{item.RegisteredPhoneNumber1} | {item.agent_code} | {item.first_name} </Option>
                      ))}
                  </Select>
                </FormItem>

                <FormItem>
                  <Button  type="primary" htmlType='button'
                  onClick={()=>{

                    if (this.state.selected_agent===""){
                      message.error("Please select agent")
                    }else{
                      this.setState({datarequested:true})

                    let form_data = new FormData();
                    form_data.append('selected_agent', this.state.selected_agent);
                    form_data.append('order_id', this.state.selected_record.id);

                    axios.post(serverconfig.backendserverurl+`/customqueries/createorder_assignment`, form_data, {
                      headers: {
                        'content-type': 'multipart/form-data'
                      }
                    })
                    .then(async(res) =>{
                      message.info(res.data.message)
                      this.setState({datarequested:false})
                      this.setState({show_assignmentModel:false})


                    })
                    .catch(error => console.log(error)) 
                    }

                  }}
                  
                  >send Assignment</Button>
                </FormItem>        

          </Modal>


          <Modal              
              visible={this.state.show_xtrachargeModel}
              title="Extra charge form"
              width={600}
              onCancel={()=>{this.setState({show_xtrachargeModel:false})}}

              footer={[
                <Button key="back" onClick={()=>{this.setState({show_xtrachargeModel:false})}}>
                  Cancel
                </Button>
                ]}
                >

               <FormItem label="Extra charge"    
                >
                  <Input  type='number'  name="Charge" 
                   placeholder="Charge" 
                   value={this.state.extra_charge}
                   onChange={(val)=>{this.setState({extra_charge:val.target.value})}} />
                </FormItem>


                <FormItem>
                  <Button  type="primary" htmlType='button'
                  onClick={()=>{

                    if (this.state.extra_charge<=0){
                      message.error("Extra charge cant be less than or equal to zero")
                    }else{
                      this.setState({datarequested:true})

                    let form_data = new FormData();
                    form_data.append('order_id', this.state.selected_record.id);
                    form_data.append('extra_charge', this.state.extra_charge);

                    axios.post(serverconfig.backendserverurl+`/customqueries/createorderextracharge`, form_data, {
                      headers: {
                        'content-type': 'multipart/form-data'
                      }
                    })
                    .then(async(res) =>{
                      message.info(res.data.message)
                      this.setState({datarequested:false})
                      this.setState({show_xtrachargeModel:false})

                    })
                    .catch(error => console.log(error)) 
                    }

                  }}
                  
                  >Apply charge</Button>
                </FormItem>        

          </Modal>

        </div>
    )


    }

   
  }
}

export default OrdersList; 
