import React from 'react';
import { GoogleMap, Polyline, LoadScript,Marker,InfoWindow } from '@react-google-maps/api';
import * as primarycolor from '../../primarycolor'
import SportsMotorsportsIcon from '@mui/icons-material/SportsMotorsports';
import * as map_apikey from '../../map_api_key'

const PolylineMap_Other = ({ initialCoordinates }) => {
  const [coordinates, setCoordinates] = React.useState(initialCoordinates);

  const mapContainerStyle = {
    width: '100%',
    height: '400px',
  };

  const center = {
    lat: 0.3476, // Kampala latitude
    lng: 32.5825, // Kampala longitude
  };

  const defaultZoom = 12; // Adjust the zoom level as needed

  const [selectedMarker, setSelectedMarker] = React.useState(null);
  const handleMarkerClick = (marker) => {
    setSelectedMarker(marker);
  };


 
  return (
    <LoadScript googleMapsApiKey={'AIzaSyD7DhyWsX8qUNdEri4hGRjDOx566po_UpA'}>


      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        center={center}
        zoom={defaultZoom} >

         {
            (
              <Marker
               title={String(coordinates.Address)+" ("+String(coordinates.location_lat)+" , "+String(coordinates.location_lng)+" )"} 
               position={{ lat: coordinates.location_lat, lng: coordinates.location_lng }}
               
               />)
          
          }

      </GoogleMap>
      </LoadScript>
  );
};

export default PolylineMap_Other;